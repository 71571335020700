export const RoutingPath = {
  /** ログイン画面 */
  apiIspLogin: "/isp/login",
  /** ポータル画面 */
  ispPortal: "/isp/portal",
  /** 企業一覧画面 */
  ispCompanyList: "/isp/companies",
  /** パスワード変更画面 */
  ispEditPassword: "/isp/profiles/password",
  /** パスワード再設定要請画面 */
  ispPasswordReissue: "/isp/login/password",
  /** パスワード再設定画面 */
  ispPasswordReset: "/isp/login/password/reset",
  /** パスワード再設定完了画面 */
  ispPasswordResetResult: "/isp/login/password/reset/result",
  /** 企業詳細画面 */
  ispCompanyDetail: "/isp/companies/:companyCode",
  /** 新規企業登録画面 */
  ispCompanyAdd: "/isp/companies/add",
  /** 企業登録変更 */
  ispCompanyEdit: "/isp/companies/:companyCode/edit",
  /** 状況一覧 */
  ispCompanyStatus: "/isp/companies/:companyCode/status",
  /** 組織管理画面 */
  ispOrgManagement: "/isp/companies/:companyCode/organizations",
  /** ユーザー一覧画面 */
  ispUserList: "/isp/users",
  /** ユーザー招待画面 */
  ispInvitationUser: "/isp/users/invite",
  /** ユーザー登録画面 */
  ispUserAdd: "/isp/users/add",
  /** ユーザー登録確認 */
  ispUserAddCheck: "/isp/users/add/check",
  /** ユーザー更新画面 */
  ispUserEdit: "/isp/users/:userId/edit",
  /** 企業ユーザー一覧画面 */
  ispCompanyUserList: "/isp/companies/:companyCode/members",
  /** 企業ユーザー新規登録 */
  ispCompanyUserAdd: "/isp/companies/:companyCode/members/add",
  /** 企業ユーザー新規登録確認 */
  ispCompanyUserAddCheck: "/isp/companies/:companyCode/members/add/check",
  /** 企業ユーザー新規登録完了 */
  ispCompanyUserAddResult: "/isp/companies/:companyCode/members/add/result",
  /** 企業ユーザー情報編集 */
  ispCompanyUserEdit: "/isp/companies/:companyCode/members/:memberId/edit",
  /** 企業ユーザー招待画面 */
  ispCompanyInvitationUser: "/isp/companies/:companyCode/members/invite",
  /** 権限管理画面 */
  ispAuthorization: "/isp/authorization",
  /** トークン認証エラー画面 */
  ispErrorToken: "/isp/errors/token",
  /** ログ出力ページ */
  ispLogExport: "/isp/log",
  /** インフォメーション一覧 */
  ispInfo: "/isp/info",
  /** インフォメーション追加 */
  ispInfoAdd: "/isp/info/add",
  /** インフォメーション詳細 */
  ispInfoDetail: "/isp/info/detail/:infoId",
  /** インフォメーション複製 */
  ispInfoClone: "/isp/info/detail/:infoId/clone",
  /** 通知先設定 */
  ispInfoSelect: "/isp/info/select",
  /** 依頼設定画面 */
  ispRequestSetting: "/isp/request",

  // 企業管理
  /** ログイン画面 */
  apiCompanyLogin: "/company/login",
  /** パスワード再設定要請画面 */
  companyPasswordReissue: "/company/login/password",
  /** パスワード再設定画面 */
  companyPasswordReset: "/company/login/password/reset",
  /** パスワード再設定完了画面 */
  companyPasswordResetResult: "/company/login/password/reset/result",
  /** 企業管理ポータル画面 */
  companyStatus: "/company/status",
  /** 組織管理画面 */
  companyOrgManagement: "/company/organizations",
  /** ユーザー一覧画面 */
  companyUserList: "/company/members",
  /** ユーザー新規登録 */
  companyUserAdd: "/company/members/add",
  /** ユーザー新規登録確認 */
  companyUserAddCheck: "/company/members/add/check",
  /** ユーザー新規登録完了 */
  companyUserAddResult: "/company/members/add/result",
  /** ユーザー情報編集 */
  companyUserEdit: "/company/members/:memberId/edit",
  /** ユーザー招待画面 */
  companyInvitationUser: "/company/members/invite",
  /** トークン認証エラー画面 */
  companyErrorToken: "/company/errors/token",
  /** 設定画面 */
  companyUserSetting: "/company/profiles",
  /** パスワード変更画面 */
  companyEditPassword: "/company/profiles/password",
  /** ログ出力ページ */
  companyLogExport: "/company/log",
  /** インフォメーション一覧 */
  companyInfo: "/company/info",
  /** インフォメーション追加 */
  companyInfoAdd: "/company/info/add",
  /** インフォメーション詳細 */
  companyInfoDetail: "/company/info/detail/:infoId",
  /** インフォメーション複製 */
  companyInfoClone: "/company/info/detail/:infoId/clone",
  /** 通知先設定 */
  companyInfoSelect: "/company/info/select",
  /** 物件一覧 */
  companyArticles: "/company/articles",
  /** 二段階認証ページ */
  companyTwoStepVerification: "/company/mfa",
};
