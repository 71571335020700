import { GetResponseMasterOrganizationsDataEntity } from "isp_cloud_api/dist/api/isp-web/master/requests/organizations"
import { useCallback, useEffect, useState } from "react"
import { apiIsp, ApiManager } from "../../../../managers/api-manager";

export const useRequestOrganizationsList = (onSuccess: (resData: GetResponseMasterOrganizationsDataEntity[]) => void) => {
  const [organizations, setOrganizations] = useState<GetResponseMasterOrganizationsDataEntity[]>([]);
  const getList = useCallback(() => {
    apiIsp.master().requests().organizations().get().then((res) => {
      setOrganizations(res.body.data);
      onSuccess(res.body.data);
    }).catch(ApiManager.errorFunc)
  }, []);

  useEffect(() => {
    getList();
  }, []);

  return {
    organizations: {
      originList: organizations,
      setList: setOrganizations,
    },
    getList,
  }
}