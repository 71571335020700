import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Header } from '../../ui/header/header';
import { SideBar } from '../../ui/sidebar/sidebar';
import { Button } from '../../ui/button/button';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { RoutingPath } from '../../../routes/routing-path';
import { SortListType, Table } from '../../table/table';
import { TableElement } from '../../table/table-element';
import { PageComponentDefaultProps } from '../../../models/page-component-default-props';
import { Pager } from '../../ui/pager/pager';
import { BreadcrumbList } from '../../ui/breadcrumb-list/breadcrumb-list';
import { breadcrumbParts } from '../../../models/breadcrumb-parts';
import { DateFormatter } from '../../../utilities';
import { apiIsp, ApiManager } from '../../../managers/api-manager';
import { CompanyData, CompanyBody } from '../../../models/company-data';
import { prefectureList } from '../../../models/prefecture-list';
import { QueryParamFormatter } from '../../../utilities/query-param-formatter';
import { Input } from '../../ui/input/input';
import { useAppSelector } from '../../../app/hooks';
import { ApiGetCompaniesResponse } from 'isp_cloud_api/dist/api/isp-web/companies/api-get-companies.response';
import { IspLogOperation } from '../../../models/logs/isp-log-operation';
import { LabeledForm } from '../../ui/input/labeled-form';
import { dialogAction } from '../../../slices/dialog-slice';

export type HasBlurElement = {
  blur: () => void,
}

export function checkBlurElement(elm: any): elm is HasBlurElement {
  return 'blur' in elm;
}

const breadcrumbList = [
  breadcrumbParts.isp.home,
  breadcrumbParts.isp.companyList,
];

export const CompanyList = (props: PageComponentDefaultProps) => {
  const { apiManger } = props;
  const [companyList, setCompanyList] = useState<CompanyData[]>([]);
  const [isCheckView, setIsCheckView] = useState(false);
  const dispatch = useDispatch();
  const { userInfo } = useAppSelector((state) => state.loginIspUserInfo);
  const [freeWord, setFreeWord] = useState<string>('');

  const [companyBody, setCompanyBody] = useState<CompanyBody>({
    current_page: 0,
    data: null,
    first_page_url: '',
    from: 0,
    last_page: 0,
    last_page_url: '',
    links: null,
    next_page_url: '',
    path: '',
    per_page: 0,
    prev_page_url: '',
    to: 0,
    total: 0,
  });

  //　表示データの全件数
  const totalData = companyBody.total;
  // 一度に表示するデータの件数
  const perData = companyBody.per_page;
  // 現在のページ
  const [currentPage, setCurrentPage] = useState(companyBody.current_page);
  const [queryFlag, setQueryFlag] = useState(true);
  // 表示開始のデータ番号
  const totalPage: number = useMemo(() => {
    const _totalPage = totalData % perData ?
      Math.floor(totalData / perData) : // 合計のページ数
      Math.floor(totalData / perData) - 1; // 合計のページ数(件数が丁度ページいっぱいで収まる時)
    return _totalPage;
  }, [totalData, perData]);
  // ソートキーの命名
  const sortKey = {
    code: 1,
    name: 2,
    storage: 3,
    createdAt: 4,
    stopAt: 5,
  };
  // 昇降の明示
  const highlowKey = {
    high: 0,
    low: 1,
  };

  const [sortBy, setSortBy] = useState(sortKey.createdAt);
  const [highlow, setHighlow] = useState(highlowKey.low);
  const [index, setIndex] = useState(6);
  const [activeSort, setActiveSort] = useState('active');
  const prevSearchText = useRef<string | undefined>('');
  // - フリーワード -
  const changeFreeWord = useCallback((v: string) => {
    setFreeWord(v);
  }, [freeWord]);
  const getCompanyList = useCallback(async (searchText?: string) => {
    if (queryFlag) {
      const query = QueryParamFormatter.queryParse().p;
      setCurrentPage(query ? Number(query) : currentPage);
    } else {
      setCurrentPage(currentPage);
    }
    const page: number | undefined = (() => {
      if (searchText !== undefined) {
        return undefined;
      }
      return currentPage + 1;
    })();
    const words = searchText !== undefined ? searchText : prevSearchText.current;
    try {
      if (searchText === prevSearchText.current && searchText !== undefined) {
        return;
      }
      const data: any = await apiIsp.companies().get({
        is_view: isCheckView,
        words,
        sort_by: sortBy,
        highlow: highlow,
        limit: 20,
        page,
      } as any);
      const body: CompanyBody[] = data.body.data;
      if (body.length) {
        setCompanyList(body as any);
        setCompanyBody(data.body as any);
        if (freeWord === prevSearchText.current) {
          return;
        }
        if (searchText !== undefined) {
          setCurrentPage(0);
          prevSearchText.current = searchText;
        }
        if (data.body.current_page > data.body.last_page) {
          setCurrentPage(data.body.last_page - 1);
        }
      } else {
        if (companyList.length) {
          if (checkBlurElement(document.activeElement)) {
            document.activeElement?.blur?.() ;
          }
          dispatch(dialogAction.pushMessage({
            title: '確認',
            message: ['検索結果が見つかりませんでした。'],
            buttons: [
              { label: 'OK', callback: () => dispatch(dialogAction.pop()) }
            ]
          }));
        }
      }
    } catch (error) {
      ApiManager.errorFunc(error);
    }
  }, [queryFlag, isCheckView, freeWord, sortBy, highlow, currentPage]);

  const handleKeyDownSearch = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && !e.nativeEvent.isComposing) {
      getCompanyList(freeWord);
    }
  }, [getCompanyList, freeWord]);
  /** 新規企業登録 */
  const handleClickAddCompany = useCallback(() => {
    IspLogOperation('companyAddClick', () => {
      const query = QueryParamFormatter.queryCompose({ p: currentPage });
      dispatch(push(RoutingPath.ispCompanyAdd + '?' + query));
    });
  }, [currentPage]);

  /** 退職者表示 */
  const handleClickShowRetire = useCallback(
    () => {
      setIsCheckView(!isCheckView);
    },
    [isCheckView, currentPage, totalPage],
  );

  /** 企業詳細表示 */
  const handleClickCompanyData = useCallback((v) => {
    IspLogOperation('companyDetailClick', () => {
      const path = RoutingPath.ispCompanyDetail
        .replace(':companyCode', v);
      const query = QueryParamFormatter.queryCompose({ p: currentPage });
      dispatch(push(path + '?' + query));
    });
  }, [currentPage]);
  /** 表示するページをセット */
  const handlePaginate = useCallback((page: number) => {
    setCurrentPage(page * perData);
  }, [currentPage]);
  /** 企業一覧取得 */
  useEffect(() => {
    if (!userInfo.user_id) {
      return;
    }
    getCompanyList();
    setQueryFlag(false);
  }, [sortBy, highlow, currentPage, isCheckView]);
  /** ソート情報まとめ */
  const sortList: SortListType[] = [
    {
      index: 0, // 何列目
      type: 'up', // 初期の昇降順
      key: sortKey.code, // 何でソートするか
      active: '', // 現在のソートならactive
      callback: () => {
      },
    },
    {
      index: 1,
      type: 'up',
      key: sortKey.name,
      active: '',
      callback: () => {
      },
    },
    {
      index: 5,
      type: 'up',
      key: sortKey.storage,
      active: '',
      callback: () => {
      },
    },
    {
      index: 6,
      type: 'up',
      key: sortKey.createdAt,
      active: '',
      callback: () => {
      },
    },
    {
      index: 7,
      type: 'up',
      key: sortKey.stopAt,
      active: '',
      callback: () => {
      },
    },
  ];
  // クリックされたソートを書き換える
  const sort = useMemo(() => {
    return sortList.filter(elm => elm.index === index);
  }, [sortList]);
  sort[0].type = highlow ? 'down' : 'up';
  sort[0].active = activeSort ? 'active' : '';

  const findPrefecture = (prefectureKey: number) => {
    const foundIndex = prefectureList.findIndex((v) => Number(v.key) === prefectureKey);
    return foundIndex > -1 ? (
      prefectureList[foundIndex].label
    ) : (
      ''
    );
  };
  // テーブル要素用意
  const head: string[] = [
    '企業ID',
    '企業名(カナ)',
    'オーナー名',
    'オーナーメールアドレス',
    '企業住所',
    'ストレージ',
    '登録日',
    '利用停止日',
    '',
  ];
  const body: TableElement[][] = [];
  // 一覧リスト生成
  companyList
    .slice(0, perData)
    .forEach(element => (
      body.push([
        element.company_code,
        <>
          <div className="name">{element.company_name}</div>
          <div className="kana">({element.company_name_kana})</div>
        </>,
        (element.family_name && element.name) ? (element.family_name + ' ' + element.name) : '',
        element.email,
        (findPrefecture(element.pref_id) + element.address + (element.others ?? '')).length > 26 ?
          (findPrefecture(element.pref_id) + element.address + (element.others ?? '')).slice(0, 26) + '...' :
          (findPrefecture(element.pref_id) + element.address + (element.others ?? '')),
        element.storage_size + 'GB',
        DateFormatter.date2str(new Date(element.created_at)),
        element.scheduled_to_stop_at ? DateFormatter.date2str(new Date(element.scheduled_to_stop_at)) : '-',
        <Button
          size="small"
          color="secondary"
          label="詳細"
          onClick={() => handleClickCompanyData(element.company_code)}
        />,
        element.is_stoped ? 'stop' : 'active',
      ])
    ));

  return (
    <div
      id="App"
      className="company list"
    >
      <SideBar currentPage="company-list" />
      <div className="main_cnt">
        <Header />
        <div className="inner">
          <BreadcrumbList breadcrumbList={breadcrumbList} />
          <section>
            <header>
              <h2>
                企業一覧
              </h2>
              <div className="count">
                <span>件数</span>
                {totalData}
                <span>件</span>
              </div>
              <div style={{ marginLeft: 'auto' }}>
                <div className="checkbox">
                  <Input
                    type="checkbox"
                    id="showRetire"
                    onClick={handleClickShowRetire}
                  />
                  <label
                    htmlFor="showRetire"
                    style={{ marginRight: '10px' }}
                    className="checked"
                  >利用停止中の企業を表示する</label>
                </div>
                <Button
                  label="新規企業登録"
                  onClick={handleClickAddCompany}
                />
              </div>
            </header>
            <div className='company_list_search'>
              <Input
                placeholder="企業名、企業名(カナ)"
                onChange={(e) => changeFreeWord(e.target.value)}
                onKeyDown={(e) => handleKeyDownSearch(e)}
                className='free_word'
              />
            </div>
            <div className="table_box">
              <Table
                head={head}
                body={body}
                alignList={['c', 'l', 'c', 'l', 'l', 'c', 'c', 'c', 'c']}
                sortList={sortList}
                setSortBy={setSortBy}
                setHighlow={setHighlow}
                setIndex={setIndex}
                setActive={setActiveSort}
              />
            </div>
            <Pager
              totalPage={totalPage}
              currentPage={currentPage}
              callback={setCurrentPage}
              onChange={(page) => handlePaginate(page)}
            />
          </section>
        </div>
      </div>
    </div>
  );
};
