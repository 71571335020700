import { forwardRef, useMemo } from "react";
import { AppAuthModel } from "../../../../models/authority/app-auth-model";
import { Checkbox } from "../../../ui/checkbox/checkbox";
import { Input } from "../../../ui/input/input";
import './auth-container.scss';
import { Validation } from '../../../../managers/validation/validation';
import React from "react";

type EditCadectData = {
  label: string,
}

type Props = {
  list: AppAuthModel['list'],
  callback: (model: AppAuthModel) => void,
  CadectCheckBox?: (props: {
    parentModel: AppAuthModel['list'][0],
    childModel: AppAuthModel['list'][0]['authorities'][0],
  }) => JSX.Element,
  CadectMainCheckBox?: (props: {
    parentModel: AppAuthModel['list'][0],
  }) => JSX.Element,
  isUser?: boolean,
  cadectData?: {
    child: {
      "model-eco": Omit<CompanyCadectChildAuthBaseProps, 'childModel' | 'parentModel'> & { ref: React.RefObject<HTMLInputElement> },
      "model-air-conditioning": Omit<CompanyCadectChildAuthBaseProps, 'childModel' | 'parentModel'> & { ref: React.RefObject<HTMLInputElement> },
      "model-disaster-prevention": Omit<CompanyCadectChildAuthBaseProps, 'childModel' | 'parentModel'> & { ref: React.RefObject<HTMLInputElement> },
      "zebzeh-simple-simulation": Omit<CompanyCadectChildAuthBaseProps, 'childModel' | 'parentModel'> & { ref: React.RefObject<HTMLInputElement> },
    },
    parent: Omit<CompanyCadectParentAuthBaseProps, 'parentModel'> & { ref: React.RefObject<HTMLInputElement> },
    parentCount: number,
  }
}
type BaseProps = {
  data: AppAuthModel['list'][0],
  callback: (model: AppAuthModel) => void,
  isUser?: boolean,
}

type ChildProps = {
  child: AppAuthModel['list'][0]['authorities'][0],
  parent: AppAuthModel['list'][0],
  callback: (model: AppAuthModel) => void,
  disabled?: boolean,
}

const ChildAuth = (props: ChildProps) => {
  const { parent, child, callback, disabled } = props;
  return (
    <div className="item_cnt">
      <div
        className={`checkbox ${(parent.checked && !disabled) ? '' : 'disabled'}`}
      >
        <Input
          id={`${parent.system}_${child.authority}`}
          type="checkBox"
          checked={child.checked}
          disabled={!parent.checked || disabled}
          onChange={() => callback(child.toggle())}
        />
        <label htmlFor={`${parent.system}_${child.authority}`}>
          {`${child.authority}／${child.description}`}
        </label>
      </div>
    </div>
  )
}

const BaseAuthContainer = (props: BaseProps) => {
  const { data, callback, isUser } = props;
  return (
    <div className="edit_box for_cadect" style={{ marginBottom: isUser ? '2rem' : '' }}>
      <div className="item_wrap fix">
        <div className="item_cnt">
          <div
              className={`checkbox`}
          >
            <Input
              id={data.system}
              type="checkBox"
              checked={data.checked}
              onChange={() => callback(data.toggle())}
            />
            <label htmlFor={data.system} className={isUser ? '' : 'bold'}>
              {`${data.system}／${data.description}`}
            </label>
          </div>
        </div>
      </div>
      { data.authorities.map((child) => (
        <div className={`item_wrap`} key={`${data.system}_${child.authority}_${child.id}`}>
          <ChildAuth parent={data} child={child} callback={callback} />
        </div>
      ))}
    </div>
  )
}

export const AuthContainer = (props: Props) => {
  const { list, callback, CadectCheckBox, CadectMainCheckBox, isUser, cadectData } = props;
  const chooseCadectData = ( auth: string ) => {
    const _auth = auth as 'model-eco' | 'model-disaster-prevention' | 'model-air-conditioning' | 'zebzeh-simple-simulation';
    if (!cadectData) return undefined;
    const data = cadectData.child[_auth];
    if (!data) return undefined;
    return data;
  }
  return (
    <>
      {list.map((parent) => (
        parent.system === 'cadect' ? (
          <div className={`edit_box ${isUser ? 'for_cadect' : 'number_box'}`} style={{ marginBottom: isUser ? '2rem' : '' }} key={parent.system}>
            {CadectMainCheckBox && <CadectMainCheckBox parentModel={parent} />}
            { cadectData &&  (
              <CompanyCadectParentAuthBase parentModel={parent} {...cadectData.parent} />
            )}
            { parent.authorities.map((child) => (
              <React.Fragment key={`${parent.system}_${child.authority}`}>
                {CadectCheckBox && <CadectCheckBox parentModel={parent} childModel={child} />}
                { cadectData && (() => {
                  const data = chooseCadectData(child.authority);
                  if (!data) {
                    return (
                      <div className={`item_wrap`} key={`${parent.system}_${child.authority}`}>
                        <ChildAuth parent={parent} child={child} callback={callback} disabled={(parent.system === 'cadect') && !Number(cadectData.parentCount) } />
                      </div>          
                    )
                  } else {
                    return (
                      <CompanyCadectChildAuthBase
                        parentModel={parent}
                        childModel={child}
                        { ...data }
                      />  
                    )
                  }
                })()
              }
              </React.Fragment>
            ))}
          </div>
        ) : (
          <BaseAuthContainer data={parent} callback={callback} isUser={isUser} />
        )
      ))}
    </>
  )
}

type CompanyCadectChildAuthBaseProps = {
  input: {
    callback: (v: string) => void,
    value: number,
  },
  check: {
    callback: (model: AppAuthModel) => void,
  },
  baseValue: {
    inputValue: number,
  },
  validations: Validation[],
  childModel: AppAuthModel['list'][0]['authorities'][0],
  parentModel: AppAuthModel['list'][0],
  disabled: boolean,
}

export const CompanyCadectChildAuthBase = forwardRef<HTMLInputElement, CompanyCadectChildAuthBaseProps>((props, ref) => {
  const { childModel, check, baseValue, validations, parentModel, input } = props;
  const authLabel = useMemo(() => {
    switch (childModel.authority) {
      case 'model-eco':
        return 'CADECT-省エネ利用者数';
      case 'model-air-conditioning':
        return 'CADECT-空調利用者数';
      case 'model-disaster-prevention':
        return 'CADECT-防災利用者数';
      case 'zebzeh-simple-simulation':
        return 'ZEB/ZEH-利用者数';
      default:
        return '';
    }
  }, [childModel.authority]);
  return (
    <div className="item_wrap">
      { authLabel ? (
        <>
          <div className="item_head">
            <Checkbox
              label={authLabel}
              id={`${parentModel.system}_${childModel.authority}`}
              checked={childModel.checked}
              onClick={() => check.callback(childModel.toggle())}
              disabled={!((Number(baseValue.inputValue) > 0) && parentModel.checked)}
            />  
          </div>
          <div className={`item_cnt ${((Number(baseValue.inputValue) > 0) && parentModel.checked && childModel.checked) ? '' : 'disabled'}`}>
            <Input
              type={'number'}
              id={`${parentModel.system}_${childModel.authority}_input`}
              value={input.value}
              className="align_right"
              onChange={(e) => input.callback(e.target.value)}
              validations={validations}
              ref={ref}
            />
            <label className="unit">
              人
            </label>
          </div>
        </>
      ) : (
        <ChildAuth child={childModel} parent={parentModel} callback={check.callback} />
      )}
  </div>
  )
});

type CompanyCadectParentAuthBaseProps = {
  input: {
    callback: (v: string) => void,
    value: number,
  },
  check: {
    callback: (model: AppAuthModel) => void,
  },
  validations: Validation[],
  parentModel: AppAuthModel['list'][0],
  cloudMemberCount: number,
}

export const CompanyCadectParentAuthBase = forwardRef<HTMLInputElement, CompanyCadectParentAuthBaseProps>((props, ref) => {
  const { check, validations, parentModel, cloudMemberCount, input } = props;
  
  return (
    <div className="item_wrap fix">
      <div className="item_head" style={{ gap: '0.5rem' }}>
        <Checkbox label="CADECT-基本利用者数" id={parentModel.system} checked={parentModel.checked} onClick={() => check.callback(parentModel.toggle())} />
      </div>
      <div className={`item_cnt ${((Number(cloudMemberCount) > 0) && parentModel.checked) ? '' : 'disabled'}`}>
        <Input
          type={'number'}
          value={input.value}
          className="align_right"
          onChange={(e) => input.callback(e.target.value)}
          validations={validations}
          ref={ref}
        /><label className="unit">人</label>
      </div>
    </div>
  )
})

type UserCadectChildAuthBaseProps = {
  check: {
    callback: (model: AppAuthModel) => void,
  },
  disabled: boolean,
  label: string,
  childModel: AppAuthModel['list'][0]['authorities'][0],
  parentModel: AppAuthModel['list'][0],
  cadectBasis: boolean,
}

export const UserCadectChildAuthBase = forwardRef<HTMLInputElement, UserCadectChildAuthBaseProps>((props, ref) => {
  const { childModel, disabled, check, label, parentModel } = props;
  return (
    <div className={`item_wrap `}>
      { label ? (
        <div className="item_cnt">
          <div
            className={`checkbox ${!disabled ? '' : 'disabled'}`}
          >
            <Input
              id={`${parentModel.system}_${childModel.authority}`}
              type="checkBox"
              onChange={() => check.callback(childModel.toggle())}
              checked={childModel.checked}
            />
            <label htmlFor={`${parentModel.system}_${childModel.authority}`}>
              {label}
            </label>
          </div>
        </div>
      ) : (
        <ChildAuth
          parent={parentModel}
          child={childModel}
          callback={check.callback}
        />
      )}
    </div>
  )
});

type UserCadectParentAuthBaseProps = {
  disabled: boolean,
  label: string,
  check: {
    callback: (model: AppAuthModel) => void,
  },
  parentModel: AppAuthModel['list'][0],
}

export const UserCadectParentAuthBase = forwardRef<HTMLInputElement, UserCadectParentAuthBaseProps>((props, ref) => {
  const { disabled, label, check, parentModel } = props;
  return (
    <div className="item_wrap fix">
      <div className="item_cnt">
        <div
          className={`checkbox ${disabled ? 'disabled' : ''}`}
        >
          <Input
            id={parentModel.system}
            type="checkBox"
            onChange={() => check.callback(parentModel.toggle())}
            checked={parentModel.checked}
          />
          <label htmlFor={parentModel.system}>
            { label }
          </label>
        </div>
      </div>
    </div>
  )
})