import React, { useCallback, useEffect, useRef, useState } from 'react';
import { push } from 'connected-react-router';
import { RoutingPath } from '../../../routes/routing-path';
import { useDispatch } from 'react-redux';
import logoLogout from '../../../assets/images/common/logo_logout.svg';
import iconMenu from '../../../assets/images/common/icon_menu.svg';
import iconLogout from '../../../assets/images/common/icon_logout.svg';
import iconPass from '../../../assets/images/common/icon_pass.svg';
import iconHuman from '../../../assets/images/common/icon_human.svg';
import iconHelp from '../../../assets/images/common/icon_help.svg';
import iconIspGuide from '../../../assets/images/common/icon_isp_guide.svg';
import iconStartGuide from '../../../assets/images/common/icon_start_guide.svg';
import iconAdminGuide from '../../../assets/images/common/icon_admin_guide.svg';
import iconTotalGuide from '../../../assets/images/common/icon_total_support.svg';
import dummy from '../../../assets/images/common/icon_uesr.svg';

import { apiIsp, apiCompany } from '../../../managers/api-manager';
import { useDidMount } from '../../../hooks/life-cycle';
import { useAppSelector } from '../../../app/hooks';
import { loginCompanyUserInfoAction } from '../../../slices/login-company-user-info-slicer';
import { persistor } from '../../../app/store';
import './header.scss';
import { dialogAction } from '../../../slices/dialog-slice';
import {
  ApiGetCompanyMemberDetailResponse,
} from 'isp_cloud_api/dist/api/company-web/members/api-get-company-member-detail.response';
import { CompanyLogOperation } from '../../../models/logs/company-log-operation';
import { ApiGetIspUserDetailResponse } from 'isp_cloud_api/dist/api/isp-web/users/api-get-isp-user-detail.response';
import { loginIspUserInfoAction } from '../../../slices/login-user-info-slicer';
import { IspLogOperation } from '../../../models/logs/isp-log-operation';

type headerProps = {
  apiManger?: 'isp' | 'company';
};

export const Header = (props: headerProps) => {
  const { apiManger } = props;
  const dispatch = useDispatch();
  const { startGuideUrl, adminGuideUrl, manuelUrl, totalGuideUrl } = useAppSelector((state) => state.serverEnv);
  const { userInfo } = useAppSelector((state) => state.loginIspUserInfo);
  const { companyUserInfo } = useAppSelector((state) => state.loginCompanyUserInfo);
  const { companyUserIcon } = useAppSelector((state) => state.loginCompanyUserInfo);
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const [isOpenHelp, setIsOpenHelp] = useState(false);
  const menuRef = useRef<HTMLUListElement>(null);
  const helpRef = useRef<HTMLUListElement>(null);

  /** ログアウト */
  const handleClickLogOut = useCallback(
    () => {
      if (apiManger === 'company') {
        CompanyLogOperation('settingLogOutClick', () => {
          apiCompany.logout().post().then(() => {
            persistor.purge().then(response => console.log(response));
            dispatch(push(RoutingPath.apiCompanyLogin));
            window.location.reload();
          });
        });
      } else {
        apiIsp.logout().post().then(() => {
          persistor.purge().then(response => console.log(response));
          dispatch(push(RoutingPath.apiIspLogin));
          window.location.reload();
        });
      }
    },
    [],
  );
  /** ユーザー設定 */
  const handleClickSetting = useCallback(
    () => {
      CompanyLogOperation('settingProfileClick', () => {
        dispatch(push(RoutingPath.companyUserSetting));
      });
    },
    [],
  );
  /** パスワード変更 */
  const handleClickEditPassword = useCallback(
    () => {
      const logOperation = apiManger === 'company' 
      ? CompanyLogOperation
      : IspLogOperation;

      const path = apiManger === 'company'
      ? RoutingPath.companyEditPassword
      : RoutingPath.ispEditPassword;
      
      logOperation('settingPasswordClick', () => {
        dispatch(push(path));
      })

    },
    [],
  );
  // TODO: ここに読み込んだリンク先への遷移を入れる
  const handleClickGuide = useCallback(
    (type: 'isp' | 'admin' | 'start' | 'total') => {
      switch (type) {
        case 'isp':
          window.open(manuelUrl);
          break;
        case 'admin':
          window.open(adminGuideUrl);
          break;
        case 'start':
          window.open(startGuideUrl);
          break;
        case 'total':
          window.open(totalGuideUrl);
          break;
        default:
          break;
      }
    },
    [],
  );

  useDidMount(() => {
    if (apiManger === 'company') {
      // 企業管理
      // ログインチェック
      apiCompany.islogin().get()
        .then(async(v) => {
          if (v.header.status_code === 401 || v.header.status_code === 403) {
            const is401 = v.header.status_code === 401;
            if (!is401) {
              try {
                await apiCompany.logout().post()
              } catch(e) {}
            }
            const message = is401
              ? ['ログインしていません', 'ログイン画面に遷移します']
              : (() => {
                let message = ['Unexpected error occurred !!'];
                if (
                  v.body
                  && (v.body.data as any).errors
                ) {
                  message = Object.values((v.body.data as any).errors).flat() as string[];
                } else if (
                  v.header
                  && v.header.messages
                  && (v.header.messages as any).message
                ) {
                  message = Object.values(v.header.messages);
                }
                return message;
              })();

            dispatch(dialogAction.pushMessage({
              title: `エラー${v.header.status_code ? ` (CODE : ${v.header.status_code})` : ''}`,
              message: message,
              buttons: [
                {
                  label: 'CLOSE',
                  callback: () => {
                    dispatch(dialogAction.pop());
                    dispatch(push(RoutingPath.apiCompanyLogin));
                  },
                },
              ],
            }));
          } else {
            // ローカルストレージが空の場合
            if (!companyUserInfo.member_id) {
              apiCompany.logout().post().then(() => {
                persistor.purge();
                dispatch(push(RoutingPath.apiCompanyLogin));
                window.location.reload();
              });
            } else {
              apiCompany.icon().get()
                .then((v) => {
                  dispatch(loginCompanyUserInfoAction.setCompanyUserIcon({
                    companyUserIcon: {
                      Filedata: v.body.data.Filedata,
                    },
                  }));
                });
            }
          }
        });
    } else {
      // ISP管理
      // ログインチェック
      apiIsp.islogin().get()
        .then(async(v) => {
          console.log(v.header);
          if (v.header.status_code === 401 || v.header.status_code === 403) {
            const is401 = v.header.status_code === 401;
            if (!is401) {
              try {
                await apiCompany.logout().post()
              } catch(e) {}
            }
            const message = is401
              ? ['ログインしていません', 'ログイン画面に遷移します']
              : (() => {
                let message = ['Unexpected error occurred !!'];
                if (
                  v.body
                  && (v.body.data as any).errors
                ) {
                  message = Object.values((v.body.data as any).errors).flat() as string[];
                } else if (
                  v.header
                  && v.header.messages
                  && (v.header.messages as any).message
                ) {
                  message = Object.values(v.header.messages);
                }
                return message;
              })();
            dispatch(dialogAction.pushMessage({
              title: `エラー${v.header.status_code ? ` (CODE : ${v.header.status_code})` : ''}`,
              message: message,
              buttons: [
                {
                  label: 'CLOSE',
                  callback: () => {
                    dispatch(dialogAction.pop());
                    dispatch(push(RoutingPath.apiIspLogin));
                  },
                },
              ],
            }));
          } else {
            // ローカルストレージが空の場合
            if (!userInfo.user_id) {
              apiIsp.logout().post().then(() => {
                persistor.purge();
                dispatch(push(RoutingPath.apiIspLogin));
                window.location.reload();
              });
            }
          }
        });
    }
  });

  /**
   * ドロップダウンメニューの状態を監視
   */
  useEffect(() => {
    isOpenMenu && menuRef.current?.focus();
  }, [isOpenMenu]);
  useEffect(() => {
    isOpenHelp && helpRef.current?.focus();
  }, [isOpenHelp]);

  useEffect(() => {
    if (apiManger === 'company') {
      (apiCompany.members(companyUserInfo.member_id).get() as Promise<ApiGetCompanyMemberDetailResponse>)
        .then((v) => {
          dispatch(loginCompanyUserInfoAction.setCompanyUserDetail({
            companyUserInfo: {
              ...companyUserInfo,
              member_id: v.body.data.member_id,
              organization_code: v.body.data.organization_code,
              occupation_id: v.body.data.occupation_id,
              industry_id: v.body.data.industry_id,
              display_name: v.body.data.display_name,
              role: v.body.data.role,
              is_owner: v.body.data.is_owner,
              is_information: v.body.data.is_information,
              is_log_admin: v.body.data.is_log_admin,
              is_cadect1: v.body.data.is_cadect1,
              is_cadect2: v.body.data.is_cadect2,
              is_cadect3: v.body.data.is_cadect3,
              is_cadect4: v.body.data.is_cadect4,
              is_valid: v.body.data.is_valid,
              organization_name:	v.body.data.organization_name,
            },
          }));
        });
    } else {
      (apiIsp.users(userInfo.user_id).get() as Promise<ApiGetIspUserDetailResponse>)
        .then((v) => {
          dispatch(loginIspUserInfoAction.setIspUserDetail({
            userInfo: {
              user_id: v.body.data.user_id,
              email: v.body.data.email,
              family_name: v.body.data.family_name,
              name: v.body.data.name,
              family_name_kana: v.body.data.family_name_kana,
              name_kana: v.body.data.name_kana,
              is_isp_owner: v.body.data.is_isp_owner,
            },
          }));
        });
    }
  }, []);

  return (
    <header>
      {apiManger === 'company' ?
        // 企業管理
        <>
          <h1>{companyUserInfo.company_name}</h1>
          <div>
            <img
              className="thum"
              src={companyUserIcon.Filedata ? companyUserIcon.Filedata : dummy}
              alt={`${companyUserInfo.display_name}さん`}
            />
            {companyUserInfo.display_name} さんでログイン中
          </div>
          <button onClick={() => setIsOpenMenu(!isOpenMenu)}>
            <img
              src={iconMenu}
              alt="メニュー"
            />
          </button>
          {isOpenMenu &&
            <ul
              className="user_box"
              onBlur={() => setIsOpenMenu(false)}
              ref={menuRef}
              tabIndex={1}
            >
              <li>
                <a
                  onClick={handleClickSetting}
                >
                  <img
                    src={iconHuman}
                    alt="表示設定"
                  />
                  表示設定
                </a>
              </li>
              <li>
                <a
                  onClick={handleClickEditPassword}
                >
                  <img
                    src={iconPass}
                    alt="パスワード変更"
                  />
                  パスワード変更
                </a>
              </li>
              <li>
                <a
                  onClick={handleClickLogOut}
                >
                  <img
                    src={iconLogout}
                    alt="ログアウト"
                  />
                  ログアウト
                </a>
              </li>
            </ul>
          }
          <button
            className={'btn_help'}
            onClick={() => setIsOpenHelp(!isOpenHelp)}
          >
            <img
              src={iconHelp}
              alt="ヘルプ"
            />
          </button>
          {isOpenHelp &&
            <ul
              className="user_box"
              onBlur={() => setIsOpenHelp(false)}
              ref={helpRef}
              tabIndex={1}
            >
              <li>
                <a
                  onClick={() => handleClickGuide('start')}
                >
                  <img
                    src={iconStartGuide}
                    alt="スタートガイド"
                  />
                  スタートガイド
                </a>
              </li>
              <li>
                <a
                  onClick={() => handleClickGuide('admin')}
                >
                  <img
                    src={iconAdminGuide}
                    alt="管理者ガイド"
                  />
                  管理者ガイド
                </a>
              </li>
              {/*TODO: step2にてGOが出るの待ち(2022/08/22)*/}
              {/*<li>*/}
              {/*  <a*/}
              {/*    onClick={() => handleClickGuide('total')}*/}
              {/*  >*/}
              {/*    <img*/}
              {/*      src={iconTotalGuide}*/}
              {/*      alt="B-LOOPトータルサポートサイト"*/}
              {/*    />*/}
              {/*    B-LOOPトータルサポートサイト*/}
              {/*  </a>*/}
              {/*</li>*/}
            </ul>
          }
        </>
        :
        // ISP管理
        <>
          <h1>B-LOOP管理サイト</h1>
          <div>{userInfo.family_name} {userInfo.name} さんでログイン中</div>
          <button onClick={() => setIsOpenMenu(!isOpenMenu)}>
            <img
              src={iconMenu}
              alt="メニュー"
            />
          </button>
          {isOpenMenu &&
            <ul
              className="user_box"
              onBlur={() => setIsOpenMenu(false)}
              ref={menuRef}
              tabIndex={1}
            >
              <li>
               <a
              onClick={handleClickEditPassword}
                >
                  <img
                    src={iconPass}
                    alt="パスワード変更"
                  />
                  パスワード変更
                </a>
              </li> 
              <li>
                <a
                  onClick={handleClickLogOut}
                >
                  <img
                    src={iconLogout}
                    alt="ログアウト"
                  />
                  ログアウト
                </a>
              </li>
            </ul>
          }
          <button
            className={'btn_help'}
            onClick={() => setIsOpenHelp(!isOpenHelp)}
          >
            <img
              src={iconHelp}
              alt="ヘルプ"
            />
          </button>
          {isOpenHelp &&
            <ul
              className="user_box"
              onBlur={() => setIsOpenHelp(false)}
              ref={helpRef}
              tabIndex={1}
            >
              <li>
                <a
                  onClick={() => handleClickGuide('isp')}
                >
                  <img
                    src={iconIspGuide}
                    alt="オンラインマニュアル"
                  />
                  オンラインマニュアル
                </a>
              </li>
            </ul>
          }
        </>
      }
    </header>
  );
};
