import React from 'react';
import './App.css';
import {Routes} from './routes/routes';
import {ConnectedRouter} from 'connected-react-router';
import {history} from './app/store';
import {Dialog} from './components/dialog/dialog';
import { Initializer } from './components/init/initializer';
import { Route, Switch } from 'react-router';
import { Loading } from './components/ui/loading/loading';

function App() {
  return (
    <>
      <ConnectedRouter history={history} >
        {Routes}
        <Switch>
          <Route  path={'/isp'} component={() => <Initializer type={'isp'} />} />
          <Route  path={'/company'} component={() => <Initializer type={'company'} />} />
        </Switch>
      </ConnectedRouter>
      <Dialog />
      <Loading />
    </>
  );
}

export default App;