import { useCallback } from "react";
import { breadcrumbParts } from "../../../models/breadcrumb-parts";
import { PageComponentDefaultProps } from "../../../models/page-component-default-props"
import { BreadcrumbList } from "../../ui/breadcrumb-list/breadcrumb-list";
import { Button } from "../../ui/button/button";
import { Header } from "../../ui/header/header";
import { SideBar } from "../../ui/sidebar/sidebar"
import './request-setting.scss';
import { useAppDispatch } from '../../../app/hooks';
import { dialogAction } from '../../../slices/dialog-slice';
import { MemberSelectDialog } from '../../dialog/member-select-dialog/member-select-dialog';
import { useGetRequestsData } from './hooks/get-requests-list-data';
import { IspLogOperation } from '../../../models/logs/isp-log-operation';

const breadCrumbList = [breadcrumbParts.isp.home, breadcrumbParts.isp.ispRequestSetting];

export const RequestSetting = (props: PageComponentDefaultProps) => {
  const { apiManger } = props;
  const dispatch = useAppDispatch();
  const { getList, persons, organizations } = useGetRequestsData();
  const onClickIndividualSetting = useCallback((mode: 'persons' | 'organizations') => {
    const title = mode === 'persons' ? '個人宛設定' : '組織宛設定';
    const param = {
      mode,
      registerList: mode === 'persons' ? persons : organizations,
      onSuccess: getList,
    }
    const logLabel = mode === 'persons'
      ? 'requestManagerPersonSettingClick'
      : 'requestManagerOrganizationSettingClick';

    IspLogOperation(logLabel);
    dispatch(dialogAction.push({
      title,
      element: <MemberSelectDialog
        {...param}
      />,
      className: 'member_select_dialog',
    }))
  }, [persons, organizations, getList]);
  return (
    <div
    id="App"
    className="request_setting"
  >
    <SideBar currentPage="request" apiManger={apiManger.type} />
    <div className="main_cnt">
        <Header />
        <div className="inner">
          <BreadcrumbList breadcrumbList={breadCrumbList} />
          <div className="inner">
            <section>
              <header>
                <h2>依頼設定</h2>
              </header>
            </section>
            <div className="request_setting__body__wrap edit_wrap">
              <div className="request_setting__body">
                <div className="request_setting__body__content_wrap">
                  <label>●&emsp;個人宛（ユーザー特定するための検索可能組織を設定）</label>
                  <div className="member_cnt">
                    {persons.map((organization) => (
                      <div className="member_card" key={`person_card_${organization.organization_code}`}>
                        {organization.organization_name}
                      </div>
                    ))}
                  </div>
                  <div className="button_cnt">
                    <Button label="設定" onClick={() => onClickIndividualSetting('persons')} />
                  </div>
                </div>
                <div className="request_setting__body__content_wrap">
                  <label>●&emsp;組織宛</label>
                  <div className="member_cnt">
                    {organizations.map((organization) => (
                      <div className="member_card" key={`person_card_${organization.organization_name}`}>
                        {organization.organization_name}
                      </div>
                    ))}
                  </div>  
                  <div className="button_cnt">
                  <Button label="設定" onClick={() => onClickIndividualSetting('organizations')} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}