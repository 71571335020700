import React, { useEffect, useState } from 'react';
import { Button } from '../../ui/button/button';
import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { push } from 'connected-react-router';
import { RoutingPath } from '../../../routes/routing-path';
import { Header } from '../../ui/header/header';
import { SideBar } from '../../ui/sidebar/sidebar';
import { PageComponentDefaultProps } from '../../../models/page-component-default-props';
import { breadcrumbParts } from '../../../models/breadcrumb-parts';
import { BreadcrumbList } from '../../ui/breadcrumb-list/breadcrumb-list';
import { useAppSelector } from '../../../app/hooks';
import { companyAction, companyDefaultData, CompanyParams } from '../../../slices/company-slice';
import { DateFormatter } from '../../../utilities';
import { prefectureList } from '../../../models/prefecture-list';
import { apiIspCommonAction } from '../../../slices/api-isp/api-isp-common-slicer';
import { useDidMount } from '../../../hooks/life-cycle';
import { apiCompany, apiIsp, ApiManager } from '../../../managers/api-manager';
import { dialogAction } from '../../../slices/dialog-slice';
import * as lodash from 'lodash';
import { Label } from '../../ui/label/label';
import { ApiGetCompanyDetailResponse } from 'isp_cloud_api/dist/api/isp-web/companies/api-get-company-detail.response';
import { IspLogOperation } from '../../../models/logs/isp-log-operation';
import './company.scss'
import {
  ApiGetAuthDlResponse
} from 'isp_cloud_api/dist/api/isp-web/companies/operation/authority/download/api-get-auth-dl.response';

const findPrefecture = (prefectureKey: number) => {
  const foundIndex = prefectureList.findIndex((v) => Number(v.key) === prefectureKey);
  return foundIndex > -1 ? (
    prefectureList[foundIndex].label
  ) : (
    ''
  );
};

export type CompanyDetailProps = {
  companyCode: string,
} & PageComponentDefaultProps;

const defaultBreadcrumbList = [
  breadcrumbParts.isp.home,
  breadcrumbParts.isp.companyList,
  breadcrumbParts.isp.companyDetail,
];

export const CompanyDetail = (props: CompanyDetailProps) => {
  const { apiManger, companyCode } = props;
  const dispatch = useDispatch();
  const { industryList, occupationList } = useAppSelector((state) => ({
    ...state.commonData,
  }), lodash.isEqual);
  const { breadcrumbName } = useAppSelector((state) => state.company);
  const [companyData, setCompanyData] = useState({
    ...companyDefaultData,
    company_name: '',
    industry_id: '',
    occupation_id: '',
    pref_id: '',
    storage_size: '',
    member_use_count: '',
    info_use_count: '',
    log_use_count: '',
    cadect1_use_count: '',
    cadect2_use_count: '',
    cadect3_use_count: '',
    cadect4_use_count: '',
    cadect5_use_count: '',
    user_cadect1_use_count: '',
    user_cadect2_use_count: '',
    user_cadect3_use_count: '',
    user_cadect4_use_count: '',
    user_cadect5_use_count: '',
    user_info_use_count: '',
    user_log_use_count: '',
    isMfa: '',
  });
  const [breadcrumbList, setBreadcrumbList] = useState(defaultBreadcrumbList);
  const {
    company_code: code,
    company_name: companyName,
    company_name_kana: kana,
    industry_id: business,
    occupation_id: occupation,
    zip_code: postalCode,
    pref_id: addressTop,
    address: addressMiddle,
    others: addressLast,
    tel: phonNumber,
    owner_id,
    storage_size: storage,
    member_use_count: useCloud,
    info_use_count: useInformation,
    log_use_count: useLogReference,
    cadect1_use_count: useCadectBasis,
    cadect2_use_count: useCadectEco,
    cadect3_use_count: useCadectAir,
    cadect4_use_count: useCadectDisaster,
    cadect5_use_count: useCadect5,
    scheduled_to_stop_at: suspensionDate,
    user_cadect1_use_count: userCadectBasis,
    user_cadect2_use_count: userCadectEco,
    user_cadect3_use_count: userCadectAir,
    user_cadect4_use_count: userCadectDisaster,
    user_cadect5_use_count: userCadect5,
    user_info_use_count: userInformation,
    user_log_use_count: userLogReference,
    is_stoped,
    created_at,
    updated_at,
    family_name: familyName,
    name,
    family_name_kana: familyNameKana,
    name_kana: nameKana,
    email,
    isMfa,
    is_outside_request: isOutSideRequest,
  } = companyData || companyDefaultData;
  const [industryName, setIndustryName] = useState('');
  const [occupationName, setOccupationName] = useState('');
  const [reuseFlag, setReuseFlag] = useState(false);
  const [resolveFlag, setResolveFlag] = useState(false);
  const { userInfo } = useAppSelector((state) => state.loginIspUserInfo);
  // -------------------- イベント用意 --------------------
  /** 組織管理押下 */
  const handleClickOrgManagement = useCallback(
    () => {
      if (is_stoped) {
        dispatch(dialogAction.pushMessage({
          title: '確認',
          message: ['この企業は利用停止中のためご利用頂けません'],
          buttons: [{
            label: 'OK',
            callback: () => {
              dispatch(dialogAction.pop());
            },
          }],
        }));
        return;
      }
      IspLogOperation('organizationManagerClick', () => {
        const path = RoutingPath.ispOrgManagement
          .replace(':companyCode', companyCode);
        dispatch(push(path));
      });
    },
    [is_stoped],
  );
  /** 企業ユーザー一覧押下 */
  const handleClickCompanyUserList = useCallback(
    () => {
      if (is_stoped) {
        dispatch(dialogAction.pushMessage({
          title: '確認',
          message: ['この企業は利用停止中のためご利用頂けません'],
          buttons: [{
            label: 'OK',
            callback: () => {
              dispatch(dialogAction.pop());
            },
          }],
        }));
        return;
      }
      IspLogOperation('companyListClick', () => {
        const path = RoutingPath.ispCompanyUserList
          .replace(':companyCode', companyCode);
        dispatch(push(path));
      });
    },
    [is_stoped],
  );
  /** 企業詳細押下 */
  const handleClickCompanyEdit = useCallback(
    () => {
      if (is_stoped) {
        dispatch(dialogAction.pushMessage({
          title: '確認',
          message: ['この企業は利用停止中のためご利用頂けません'],
          buttons: [{
            label: 'OK',
            callback: () => {
              dispatch(dialogAction.pop());
            },
          }],
        }));
        return;
      }
      if (!owner_id) {
        dispatch(dialogAction.pushMessage({
          title: '確認',
          message: ['招待済みのオーナーが登録を完了できていません', '招待メールから登録を完了してください'],
          buttons: [{
            label: 'OK',
            callback: () => {
              dispatch(dialogAction.pop());
            },
          }],
        }));
        return;
      }
      IspLogOperation('companyDetailClick', () => {
        const path = RoutingPath.ispCompanyEdit
          .replace(':companyCode', companyCode);
        dispatch(push(path));
      });
    },
    [is_stoped, owner_id],
  );
  /** 企業状況押下 */
  const handlerClickStatus = useCallback(
    () => {
      if (is_stoped) {
        dispatch(dialogAction.pushMessage({
          title: '確認',
          message: ['この企業は利用停止中のためご利用頂けません'],
          buttons: [{
            label: 'OK',
            callback: () => {
              dispatch(dialogAction.pop());
            },
          }],
        }));
        return;
      }
      IspLogOperation('companyStatusClick', () => {
        const path = RoutingPath.ispCompanyStatus
          .replace(':companyCode', companyCode);
        dispatch(push(path));
      });
    },
    [is_stoped]);
  /** 利用再開押下 */
  const handleClickReUse = () => {
    dispatch(dialogAction.pushMessage({
      title: '確認',
      message: ['企業の利用を再開してよろしいですか'],
      buttons: [{
        label: 'キャンセル',
        callback: () => {
          dispatch(dialogAction.pop());
        },
      },
        {
          label: 'OK',
          callback: () => {
            apiIsp.companies(companyCode).reuse().post()
              .then(() => {
                setReuseFlag(false);
                dispatch(dialogAction.pop());
              });
          },
        }],
    }));
  };
  const AUTH_ID_LIST = {
    info: '1',
    log: '2',
    base: '101',
    eco: '102',
    air: '103',
    disaster: '104',
    zebzeh: '105',
  }
  type AuthIdType = '1' | '2' | '101' | '102' | '103' | '104' | '105';

  /** CSVのDL */
  const onDlCSV = useCallback((id: AuthIdType) => {
    (apiIsp.companies(companyCode).operation().authority().download().get(id as any) as Promise<ApiGetAuthDlResponse>)
      .then((v) => {
        const a = document.createElement('a');
        const url = URL.createObjectURL(v.body);
        a.download = v.fileName;
        a.href = url;
        a.click();
        URL.revokeObjectURL(url);
        a.remove();
      }).catch((error) => {
      ApiManager.errorFunc(error);
    });
  }, []);

  // -------------------- effect --------------------
  useEffect(() => {
    if (!userInfo.user_id) {
      return;
    }
    (apiIsp.companies(companyCode).get() as Promise<ApiGetCompanyDetailResponse>)
      .then((v) => {
        const Data = (v.body.data);
        setCompanyData({
          ...v.body.data,
          industry_id: String(v.body.data.industry_id),
          occupation_id: String(v.body.data.occupation_id),
          pref_id: String(v.body.data.pref_id),
          storage_size: String(v.body.data.storage_size),
          member_use_count: String(v.body.data.member_use_count),
          info_use_count: String(v.body.data.info_use_count),
          log_use_count: String(v.body.data.log_use_count),
          cadect1_use_count: String(v.body.data.cadect1_use_count),
          cadect2_use_count: String(v.body.data.cadect2_use_count),
          cadect3_use_count: String(v.body.data.cadect3_use_count),
          cadect4_use_count: String(v.body.data.cadect4_use_count),
          cadect5_use_count: String(v.body.data.cadect5_use_count),
          user_cadect1_use_count: String(v.body.data.user_cadect1_use_count - v.body.data.support_cadect1_use_count),
          user_cadect2_use_count: String(v.body.data.user_cadect2_use_count - v.body.data.support_cadect2_use_count),
          user_cadect3_use_count: String(v.body.data.user_cadect3_use_count - v.body.data.support_cadect3_use_count),
          user_cadect4_use_count: String(v.body.data.user_cadect4_use_count - v.body.data.support_cadect4_use_count),
          user_cadect5_use_count: String(v.body.data.user_cadect5_use_count - v.body.data.support_cadect5_use_count),
          user_info_use_count: String(v.body.data.user_info_use_count - v.body.data.support_info_use_count),
          user_log_use_count: String(v.body.data.user_log_use_count - v.body.data.support_log_use_count),
          isMfa: v.body.data.is_mfa ? 'する' : 'しない',
        });
        dispatch(companyAction.setBreadcrumbName({
          breadcrumbName: Data.company_name,
        }));
        breadcrumbParts.isp.companyDetail.label = Data.company_name.length > 20 ?
          Data.company_name.slice(0, 20) + '...' :
          Data.company_name;
        setBreadcrumbList([
          breadcrumbParts.isp.home,
          breadcrumbParts.isp.companyList,
          breadcrumbParts.isp.companyDetail,
        ]);
        setResolveFlag(true);
      });
    return () => {
      setCompanyData({
        ...companyDefaultData,
        company_name: '',
        industry_id: '',
        occupation_id: '',
        pref_id: '',
        storage_size: '',
        member_use_count: '',
        info_use_count: '',
        log_use_count: '',
        cadect1_use_count: '',
        cadect2_use_count: '',
        cadect3_use_count: '',
        cadect4_use_count: '',
        cadect5_use_count: '',
        user_cadect1_use_count: '',
        user_cadect2_use_count: '',
        user_cadect3_use_count: '',
        user_cadect4_use_count: '',
        user_cadect5_use_count: '',
        user_info_use_count: '',
        user_log_use_count: '',
        isMfa: '',
      });
      breadcrumbParts.isp.companyDetail.label = '';
    };
  }, [reuseFlag]);

  useEffect(() => {
    if (!userInfo.user_id) {
      return;
    }
    if (is_stoped) {
      setReuseFlag(true);
    }
    if (resolveFlag) {
      const foundIndex = industryList.findIndex((v) => v.id === Number(business));
      if (foundIndex > -1) {
        setIndustryName(industryList[foundIndex].name);
      }
      const occupationFoundIndex = occupationList.findIndex((v) => v.id === Number(occupation));
      if (occupationFoundIndex > -1) {
        setOccupationName(occupationList[occupationFoundIndex].name);
      }
    }
  }, [resolveFlag, is_stoped, occupationList, industryList]);
  useDidMount(() => {
    if (!userInfo.user_id) {
      return;
    }
    dispatch(apiIspCommonAction.getIndustryList());
    dispatch(apiIspCommonAction.getOccupationList());
  });
  return (
    <div
      id="App"
      className="company detail companyDetail"
    >
      <SideBar currentPage="company-list" />
      <div className="main_cnt">
        <Header />
        <div className="inner">
          <BreadcrumbList breadcrumbList={breadcrumbList} />
          <section>
            <header>
              <h2>企業詳細
                {is_stoped &&
                <span
                  style={{
                    display: 'inlineBlock',
                    marginLeft: '5px',
                    padding: '3px',
                    verticalAlign: 'middle',
                    textAlign: 'center',
                    lineHeight: '20px',
                    width: '38px',
                    height: '20px',
                    fontSize: '15px',
                    color: '#FFF',
                    backgroundColor: '#F90000',
                  }}
                >利用停止</span>
                }
              </h2>
              <div className="btn_box">
                <Button
                  label="企業編集"
                  onClick={handleClickCompanyEdit}
                />
                <Button
                  label="状況一覧"
                  onClick={handlerClickStatus}
                />
                <Button
                  label="組織管理"
                  onClick={handleClickOrgManagement}
                />
                <Button
                  label="企業ユーザー管理"
                  onClick={handleClickCompanyUserList}
                />
              </div>
            </header>
            <div className="edit_wrap">
              <h3>■企業情報</h3>
              <div className="edit_box">
                <div className="item_wrap">
                  <div className="item_head">
                    企業コード
                  </div>
                  <div className="item_cnt">
                    <Label str={code} />
                  </div>
                </div>
                <hr />
                <div className="item_wrap">
                  <div className="item_head">
                    企業名
                  </div>
                  <div className="item_cnt">
                    <Label str={companyName} />
                  </div>
                </div>
                <div className="item_wrap">
                  <div className="item_head">
                    企業（カナ）
                  </div>
                  <div className="item_cnt">
                    <Label str={kana} />
                  </div>
                </div>
                <hr />
                <div className="item_wrap">
                  <div className="item_head">
                    業種
                  </div>
                  <div className="item_cnt">
                    <Label str={industryName} />
                  </div>
                </div>
                <hr />
                <div className="item_wrap">
                  <div className="item_head">
                    郵便番号
                  </div>
                  <div className="item_cnt">
                    <Label str={'〒' + postalCode} />
                  </div>
                </div>
                <div className="item_wrap">
                  <div className="item_head">
                    都道府県
                  </div>
                  <div className="item_cnt">
                    <Label str={findPrefecture(Number(addressTop))} />
                  </div>
                </div>
                <div className="item_wrap">
                  <div className="item_head">
                    企業住所（市区町村・番地）
                  </div>
                  <div className="item_cnt">
                    <Label str={addressMiddle} />
                  </div>
                </div>
                <div className="item_wrap">
                  <div className="item_head">
                    企業住所（ビル名等）
                  </div>
                  <div className="item_cnt">
                    {addressLast ?
                      <Label str={addressLast} /> :
                      <>{addressLast}</>
                    }
                  </div>
                </div>
                <hr />
                <div className="item_wrap">
                  <div className="item_head">
                    電話番号
                  </div>
                  <div className="item_cnt">
                    <Label str={phonNumber} />
                  </div>
                </div>
                <div className="item_wrap">
                  <div className="item_head">
                    社外向け依頼設定
                  </div>
                  <div className="item_cnt">
                    <Label str={isOutSideRequest ? 'する' : 'しない'} />
                  </div>
                </div>
              </div>
              <h3>■オーナー情報</h3>
              <div className="edit_box">
                <div className="item_wrap">
                  <div className="item_head">
                    企業担当者名
                  </div>
                  <div className="item_cnt">
                    <Label str={(familyName && name) ? (familyName + ' ' + name) : ''} />
                  </div>
                </div>
                <div className="item_wrap">
                  <div className="item_head">
                    企業担当者名(カナ)
                  </div>
                  <div className="item_cnt">
                    <Label str={(familyNameKana && nameKana) ? (familyNameKana + ' ' + nameKana) : ''} />
                  </div>
                </div>
                <hr />
                <div className="item_wrap">
                  <div className="item_head">
                    企業担当者(メールアドレス)
                  </div>
                  <div className="item_cnt">
                    <Label str={email} />
                  </div>
                </div>
                <hr />
                <div className="item_wrap">
                  <div className="item_head">
                    職種
                  </div>
                  <div className="item_cnt">
                    <Label str={occupationName} />
                  </div>
                </div>
              </div>
              <h3>■利用者情報</h3>
              <div className="edit_box">
                <div className="item_wrap">
                  <div className="item_head">
                    ストレージ
                  </div>
                  <div className="item_cnt">
                    <Label str={storage.toLocaleString() + 'GB'} />
                  </div>
                </div>
              </div>
              <h3>■認証</h3>
              <div className="edit_box">
                <div className="item_wrap">
                  <div className="item_head">
                    二段階認証
                  </div>
                  <div className="item_cnt">
                    <Label str={isMfa} />
                  </div>
                </div>
              </div>
              <div className="edit_box number_box">
                <div className="item_wrap fix">
                  <div className="item_head">
                    B-LOOP利用者数
                  </div>
                  <div className="item_cnt">
                    <Label str={useCloud.toLocaleString() + '人'} />
                  </div>
                </div>

                <div className="item_wrap">
                  <div className="item_head">
                    インフォメーション利用者数
                  </div>
                  <div className="item_cnt operationMember">
                    <Label str={useInformation.toLocaleString() + '人'} />
                    <div>操作権限設定者数</div>
                    <Label str={userInformation.toLocaleString() + '人'} />
                      <Button label="CSV出力" onClick={() => onDlCSV(AUTH_ID_LIST.info as AuthIdType)} />
                  </div>
                </div>

                <div className="item_wrap">
                  <div className="item_head">
                    ログ参照利用者数
                  </div>
                  <div className="item_cnt operationMember">
                    <Label str={useLogReference.toLocaleString() + '人'} />
                    <div>操作権限設定者数</div>
                    <Label str={userLogReference.toLocaleString() + '人'} />
                     <Button label="CSV出力" onClick={() => onDlCSV(AUTH_ID_LIST.log as AuthIdType)} />
                  </div>
                </div>

                <div className="edit_box number_box">
                  <div className="item_wrap fix">
                    <div className="item_head">
                      CADECT利用者数
                    </div>
                    <div className="item_cnt operationMember">
                      <Label str={useCadectBasis.toLocaleString() + '人'} />
                    </div>
                  </div>

                  <div className="item_wrap">
                    <div className="item_head">
                      基本利用者数
                    </div>
                    <div className="item_cnt operationMember">
                      <Label str={useCadectBasis.toLocaleString() + '人'} />
                      <div>操作権限設定者数</div>
                      <Label str={userCadectBasis.toLocaleString() + '人'} />
                        <Button label="CSV出力" onClick={() => onDlCSV(AUTH_ID_LIST.base as AuthIdType)} />
                    </div>
                  </div>
                  <div className="item_wrap">
                    <div className="item_head">
                      省エネ利用者数
                    </div>
                    <div className="item_cnt operationMember">
                      <Label str={useCadectEco.toLocaleString() + '人'} />
                      <div>操作権限設定者数</div>
                      <Label str={userCadectEco.toLocaleString() + '人'} />
                        <Button label="CSV出力" onClick={() => onDlCSV(AUTH_ID_LIST.eco as AuthIdType)} />
                    </div>
                  </div>
                  <div className="item_wrap">
                    <div className="item_head">
                      空調利用者数
                    </div>
                    <div className="item_cnt operationMember">
                      <Label str={useCadectAir.toLocaleString() + '人'} />
                      <div>操作権限設定者数</div>
                      <Label str={userCadectAir.toLocaleString() + '人'} />
                        <Button label="CSV出力" onClick={() => onDlCSV(AUTH_ID_LIST.air as AuthIdType)} />
                    </div>
                  </div>
                  <div className="item_wrap">
                    <div className="item_head">
                      防災利用者数
                    </div>
                    <div className="item_cnt operationMember">
                      <Label str={useCadectDisaster.toLocaleString() + '人'} />
                      <div>操作権限設定者数</div>
                      <Label str={userCadectDisaster.toLocaleString() + '人'} />
                        <Button label="CSV出力" onClick={() => onDlCSV(AUTH_ID_LIST.disaster as AuthIdType)} />
                    </div>
                  </div>
                  <div className="item_wrap">
                    <div className="item_head">
                      ZEB/ZEH利用者数
                    </div>
                    <div className="item_cnt operationMember">
                      <Label str={useCadect5.toLocaleString() + '人'} />
                      <div>操作権限設定者数</div>
                      <Label str={userCadect5.toLocaleString() + '人'} />
                        <Button label="CSV出力" onClick={() => onDlCSV(AUTH_ID_LIST.zebzeh as AuthIdType)} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="align_center date_box">
                <strong>
                  利用停止日：{suspensionDate ? (
                  DateFormatter.date2str(new Date(suspensionDate))
                ) : (
                  '未設定'
                )}
                </strong>
              </div>
              {is_stoped && <div className="align_center date_box">
                <Button
                  label={'利用再開'}
                  onClick={handleClickReUse}
                />
              </div>}
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};
