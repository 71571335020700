import lodash from 'lodash';
import loading from '../../../assets/images/common/icon_loading.svg';
import { useAppSelector } from '../../../app/hooks';
import { useMemo, useRef, useState } from 'react';

export const Loading = () => {
  const { defaultConnecting } = useAppSelector((state) => ({
    defaultConnecting: state.system.defaultConnection,
  }), lodash.isEqual);
  const [defaultConnect, setDefaultConnect] = useState(false);
  const timer = useRef(0);
  useMemo(() => {
    if (defaultConnecting) {
      window.clearTimeout(timer.current);
      setDefaultConnect(true);
    }
    timer.current = window.setTimeout(() => {
      setDefaultConnect(false);
    }, 400);
  }, [defaultConnecting]);

  return (
    <>
      {(defaultConnect)
        ? (
          <>
            <div id="loading">
              <div>
                <img src={loading} alt="loading" />
              </div>
            </div>
          </>
        )
        : (
          <>
          </>
        )}
    </>
  );
};
