import React, { useCallback, useMemo, useState } from 'react';
import { useAppDispatch } from '../../app/hooks';
import iconLink from '../../assets/images/common/icon_link.svg';
import { useDidMount } from '../../hooks/life-cycle';
import { apiIsp, ApiManager, apiCompany } from '../../managers/api-manager';
import { dialogAction } from '../../slices/dialog-slice';
import { InfoParams } from '../../slices/information-slice';
import { DateFormatter } from '../../utilities';
import { createPlaneText } from '../../utilities/create-plane-text';
import '../pages/information/information-detail.scss';
import {
  InfomationCategoryDataEntity,
  InfomationDetailEntity,
  InfomationLevelDataEntity, NotificationDetaEntity
} from '../pages/information/type/infomation.type';
import { Table } from '../table/table';
import { Button } from '../ui/button/button';
import { SkeletonLabel } from '../ui/label/label';
import { RichText } from '../ui/rich-text/rich-text';
import './information-confirmation-dialog.scss';
import { OutputData } from '@editorjs/editorjs';


const formatEmpty = (v: string | null | undefined) => v ?? '';

type Props = {
  info: InfoParams | null;
  sendDates: NotificationDetaEntity[];
  callback?: () => void;
  apiType: 'company' | 'isp';
}

const DetailBox = (props: Props) => {
  const { info, sendDates, apiType } = props;

  /* State */
  const [levelList, setLevelList] = useState<InfomationLevelDataEntity[] | null>(null);
  const [categoryList, setCategoryList] = useState<InfomationCategoryDataEntity[] | null>(null);

  /* Memo */
  const api = useMemo(() => apiType === 'company' ? apiCompany : apiIsp, [apiType]);
  const level = useMemo(() => {
    if (info && levelList) {
      const lv = info.level ? 2 : 1;
      return levelList.find((v) => v.id === lv)?.name ?? '';
    } else {
      return ''
    }
  }, [info, levelList]);
  const category = useMemo(() => {
    if (info && categoryList) {
      return categoryList.find((v) => v.id === info.category)?.name ?? '';
    } else {
      return ''
    }
  }, [info, categoryList]);
  const notificationDate = useMemo(() => {
    if (!info) return '';
    if (!info.notificationStartDate || !info.notificationEndDate) return '';
    const start = DateFormatter.date2str(info.notificationStartDate, undefined, '/');
    const end = DateFormatter.date2str(info.notificationEndDate, undefined, '/');
    return `${start}　～　${end}`;
  }, [info]);
  const getCategoryList = useCallback(async () => {
    const info = await api.informations().categories().get();
    const body = info.body.data;
    setCategoryList(body);
  }, []);

  const getLevelList = useCallback(async () => {
    const info = await api.informations().levels().get();
    const body = info.body.data;
    setLevelList(body);
  }, [])

  useDidMount(() => {
    getCategoryList();
    getLevelList();
  })

  return (
    <div className="detail_box">
      <div className="detail_box__inner edit_box">
        <div className="first_part">
          <div className="item_wrap">
            <div className="item_head">タイトル</div>
            <div className="item_cnt">
              <SkeletonLabel
                str={formatEmpty(info?.title)}
                isLoading={!!info}
              />
            </div>
          </div>
          <hr />
          <div className="item_wrap">
            <div className="item_head">内容</div>
            <div className="item_cnt">
              {/* <SkeletonLabel
                str={formatEmpty(info?.body)}
                isLoading={!!info}
              /> */}
              <RichText
                callback={() => {}}
                isReadOnly
                initData={info && info.body ? info.body as OutputData : undefined}
              />
            </div>
          </div>
        </div>

        <div className="second_part">
          <div className="second_part__inner">
            <hr />
            <div className="item_wrap">
              <div className="item_head">カテゴリ</div>
              <div className="item_cnt">
                <SkeletonLabel
                  str={category}
                  isLoading={!!(info && categoryList)}
                />
              </div>
            </div>
            <hr />
            <div className="item_wrap">
              <div className="item_head">通知レベル</div>
              <div className="item_cnt">
                <SkeletonLabel
                  str={level}
                  isLoading={!!(info && levelList)}
                />
              </div>
            </div>
            <hr />
            <div className="item_wrap">
              <div className="item_head">通知URL</div>
              <div className="item_cnt">
                {
                  (!!info?.isUrl && !!info?.url) && (
                    <div>
                      {info.url}
                      <img
                        onClick={(e) => {
                          e.stopPropagation();
                          window.open(info?.url ?? '');
                        }}
                        src={iconLink}
                        alt=""
                      />
                    </div>
                  )
                }
              </div>
            </div>
            <hr />
            <div className="item_wrap">
              <div className="item_head">通知開始日 / 通知終了日</div>
              <div className="item_cnt">
                <SkeletonLabel
                  str={notificationDate}
                  isLoading={!!info}
                />
              </div>
            </div>
            <hr />
            <div className="item_wrap">
              <div className="item_head">差出人</div>
              <div className="item_cnt">
                <SkeletonLabel
                  str={info?.senderName ?? ''}
                  isLoading={!!info}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};



export const InformationConfirmationDialog = (props: Props) => {
  const { info, sendDates, callback, apiType } = props;
  /* Hooks */
  const dispatch = useAppDispatch();
  const [detailData, setDetailData] = useState<InfomationDetailEntity | null>(null);
  const [isDisabled, setIsDisabled] = useState(false);

  const tableHead = [
    '企業名',
    '企業ユーザー名',
    'メールアドレス',
  ];

  const api = useMemo(() => apiType === 'company' ? apiCompany : apiIsp, [apiType]);

  const tableBody = useMemo(() => {
    if (!sendDates) return [];
    return sendDates.map((v) => [
      v.company_name,
      v.display_name,
      v.email,
    ])
  }, [sendDates])

  const onClickRegistration = useCallback(() => {
    if (!info) return;
    const body = JSON.stringify(info.body)
    dispatch(dialogAction.pushMessage({
      title: '確認',
      message: [
        `登録処理を実行中です...`,
      ],
      buttons: []
    }));
    const senderListJson = JSON.stringify(sendDates.map((v) => ({ company_code: v.company_code, member_id: v.member_id })), null, 2);
    api.informations().post({
      title: info.title,
      body: info.isUrl ? undefined : body,
      body_text: info.isUrl ? undefined : createPlaneText(info.body as OutputData),
      category: info.category,
      is_url: info.isUrl || undefined,
      url: info.isUrl ? info.url || undefined : undefined,
      level: info.level ? 2 : 1,
      notification_end_date: info.notificationEndDate ? DateFormatter.date2str(info?.notificationEndDate, 'YYYYMMDD', '-') : '',
      notification_start_date: info.notificationStartDate ? DateFormatter.date2str(info?.notificationStartDate, 'YYYYMMDD', '-') : '',
      sender_name: info.senderName ?? '',
      sender_list: senderListJson,
    }).then(() => {
      dispatch(dialogAction.pop());
      dispatch(dialogAction.pushMessage({
        title: '完了',
        message: [
          `登録完了しました。`,
        ],
        buttons: [
          {
            label: 'OK',
            callback: () => {
              setIsDisabled(false);
              dispatch(dialogAction.popAll());
              callback?.();
            },
            color: 'primary',
          }
        ]
      }))
    }).catch((e) => {
      dispatch(dialogAction.popAll());
      ApiManager.errorFunc(e);
    })
  }, [info, sendDates]);

  return (
    <>
      <DetailBox
        apiType={apiType}
        info={info}
        sendDates={sendDates}
      />
      <section>
        <header>通知先ユーザー一覧</header>
        <div className="border_wrap">
          <Table
            head={tableHead}
            body={tableBody}
            alignList={['c', 'c', 'c']}
            setSortBy={() => {
            }}
            setHighlow={() => {
            }}
          />
        </div>
      </section>
      <footer>
        <Button label="キャンセル" color="tertiary" onClick={() => dispatch(dialogAction.pop())} size="large" />
        <Button label="登録" disabled={isDisabled} onClick={() => {
          setIsDisabled(true);
          onClickRegistration();
        }} size="large" />
      </footer>
    </>
  );
};
