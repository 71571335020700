import { Header } from '../../ui/header/header';
import { SideBar } from '../../ui/sidebar/sidebar';
import { BreadcrumbList } from '../../ui/breadcrumb-list/breadcrumb-list';
import { breadcrumbParts } from '../../../models/breadcrumb-parts';
import { UseStorageTip } from '../portal/use-storage-tip';
import { PageComponentDefaultProps } from '../../../models/page-component-default-props';
import icon_1 from '../../../assets/images/common/icon_uesr.svg';
import icon_2 from '../../../assets/images/common/icon_property.svg';
import '../../../tip.scss';
import React, { useEffect, useState } from 'react';
import { apiIsp, apiCompany } from '../../../managers/api-manager';
import { RoutingPath } from '../../../routes/routing-path';
import { CompanyPortalData } from '../../../models/portal-data';
import { companyAction, companyDefaultData, CompanyParams } from '../../../slices/company-slice';
import { useAppSelector } from '../../../app/hooks';
import { CompanyData } from '../../../models/company-data';
import { ApiGetCompanyDetailResponse } from 'isp_cloud_api/dist/api/isp-web/companies/api-get-company-detail.response';
import { useDispatch } from 'react-redux';

export type CompanyStatusProps = {} & PageComponentDefaultProps;

const ispBreadcrumbList = [
  breadcrumbParts.isp.home,
  breadcrumbParts.isp.companyList,
  breadcrumbParts.isp.companyDetail,
  breadcrumbParts.isp.companyStatus,
];

export const CompanyStatus = (props: { companyCode?: string; } & PageComponentDefaultProps) => {
  const { companyCode, apiManger } = props;
  const dispatch = useDispatch();
  const { userInfo } = useAppSelector((state) => state.loginIspUserInfo);
  const { companyUserInfo } = useAppSelector((state) => state.loginCompanyUserInfo);
  const { breadcrumbName } = useAppSelector((state) => state.company);

  const [breadcrumbList, setBreadcrumbList] = useState(ispBreadcrumbList);

  const [companyData, setCompanyData] = useState<CompanyParams>(companyDefaultData);
  // 企業ポータル情報
  const [portalData, setPortalData] = useState<CompanyPortalData>({
    organization_count: 0,
    member_count: 0,
    article_count: 0,
    plan_count: 0,
    storage_size: 5,
    storage_use_count: 0,
    support_count: 0,
  });
  const [resolveFlag, setResolveFlag] = useState(false);

  useEffect(() => {
    let unmounted = false;
    if (apiManger.type === 'isp') {
      if (!userInfo.user_id) {
        return;
      }
      apiIsp.companies(companyCode).portals().get()
        .then((v) => {
          if (!unmounted) {
            setPortalData(v.body.data);
          }
        });
      if (!breadcrumbName) {
        (apiIsp.companies(companyCode).get() as Promise<ApiGetCompanyDetailResponse>)
          .then((v) => {
            if (!unmounted) {
              const data: CompanyParams = v.body.data;
              setCompanyData(v.body.data);
              const path = RoutingPath.ispCompanyDetail
                .replace(':companyCode', companyCode!);
              dispatch(companyAction.setBreadcrumbName({
                breadcrumbName: data.company_name,
              }));
              breadcrumbParts.isp.companyDetail.route = path;
              breadcrumbParts.isp.companyDetail.label = data.company_name.length > 20 ?
                data.company_name.slice(0, 20) + '...' :
                data.company_name;
              setBreadcrumbList([
                breadcrumbParts.isp.home,
                breadcrumbParts.isp.companyList,
                breadcrumbParts.isp.companyDetail,
                breadcrumbParts.isp.companyStatus,
              ]);
              setResolveFlag(true);
            }
          });
      } else {
        const path = RoutingPath.ispCompanyDetail
          .replace(':companyCode', companyCode!);
        breadcrumbParts.isp.companyDetail.route = path;
        breadcrumbParts.isp.companyDetail.label = breadcrumbName.length > 20 ?
          breadcrumbName.slice(0, 20) + '...' :
          breadcrumbName;
        setBreadcrumbList([
          breadcrumbParts.isp.home,
          breadcrumbParts.isp.companyList,
          breadcrumbParts.isp.companyDetail,
          breadcrumbParts.isp.companyStatus,
        ]);
        setResolveFlag(true);
      }
    } else {
      if (!companyUserInfo.member_id) {
        return;
      }
      apiCompany.portals().get()
        .then((v) => {
          if (!unmounted) {
            setPortalData({
              organization_count: v.body.data.organization_count,
              member_count: v.body.data.user_count,
              article_count: v.body.data.article_count,
              plan_count: v.body.data.plan_count,
              storage_size: v.body.data.allocated_size,
              storage_use_count: v.body.data.used_size,
              support_count: 0,
            });
          }
        });
    }
    return () => {
      unmounted = true;
    };
  }, []);

  return (
    <div
      id="App"
      className="company status"
    >
      <SideBar
        currentPage="portal"
        apiManger={apiManger.type}
      />
      <div className="main_cnt">
        <Header apiManger={apiManger.type} />
        <div className="inner">
          {apiManger.type === 'isp' ?
            breadcrumbName || resolveFlag ?
              <BreadcrumbList breadcrumbList={breadcrumbList} /> :
              <></>
            : <></>}
          {apiManger.type === 'isp'
          ? resolveFlag
            ? <h2>管理ポータル(
              {breadcrumbName
                ? breadcrumbName
                : companyData.company_name}様)</h2>
            : <h2>管理ポータル</h2>
            : <h2>企業管理ポータル</h2>
          }
          <section>

            <div className="tip_box">
              <div className="tip_wraper tip_nums">
                <div className="box_shadow">
                  <div className="tip">
                    <img
                      src={icon_1}
                      alt="ユーザー登録数"
                    />
                    <div className="text_box fix_height">
                      <h3>ユーザー登録数</h3>
                      <div className="count">{portalData.member_count - (portalData.support_count ?? 0)}<span>人</span></div>
                    </div>
                  </div>
                  <hr />
                  <div className="tip">
                    <div className="text_box second fix_height">
                      <h3>組織数</h3>
                      <div className="count">{portalData.organization_count}<span>組織</span></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="tip_wraper tip_nums">
                <div className="box_shadow">
                  <div className="tip">
                    <img
                      src={icon_2}
                      alt="物件数"
                    />
                    <div className="text_box fix_height">
                      <h3>物件数</h3>
                      <div className="count">{portalData.article_count}<span>件</span></div>
                    </div>
                  </div>
                  <hr />
                  <div className="tip">
                    <div className="text_box second fix_height">
                      <h3>プラン数</h3>
                      <div className="count">{portalData.plan_count}<span>件</span></div>
                    </div>
                  </div>
                </div>

              </div>
              <UseStorageTip
                organization=""
                limitGb={portalData.storage_size}
                usedGb={portalData.storage_use_count}
              />
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};
