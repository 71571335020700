import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type SystemState = {
  defaultConnection: boolean,
};

const initialState: SystemState = {
  defaultConnection: false,
};

export const systemSlice = createSlice({
  name: 'server-env',
  initialState,
  reducers: {
    setDefaultConnection: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.defaultConnection = action.payload;
    },
  },
});

export const systemAction = systemSlice.actions;
export const systemReducer = systemSlice.reducer;
