import React from 'react';
import { useDidMount } from '../../hooks/life-cycle';
import { ApiManager } from '../../managers/api-manager';
import { useDispatch } from 'react-redux';
import { envAction } from '../../slices/env-slice';
import { dialogAction } from '../../slices/dialog-slice';
import { serverEnvAction } from '../../slices/server-env-slice';
import { systemAction } from '../../slices/system.slice';

type Props = {
  type?: 'isp' | 'company' | 'other'
}

export const Initializer = (props: Props) => {
  const { type } = props
  const dispatch = useDispatch();
  useDidMount(() => {
    // ローディングイベントの貼り付け
    ApiManager.setConnectionEvent(
      () => dispatch(systemAction.setDefaultConnection(true)),
      () => dispatch(systemAction.setDefaultConnection(false)),
    )
    // - エラーダイアログ表示処理の登録 -
    ApiManager.onError = (e) => {
      if (e instanceof Response) {
        dispatch(dialogAction.pushMessage({
          title: `エラー${e.status ? ` (CODE : ${e.status})` : ''}`,
          message: ['ネットワークエラーが発生しました'],
          buttons: [
            {
              label: 'CLOSE',
              callback: () => {
                dispatch(dialogAction.pop());
              },
            }
          ],
        }));
        return;
      }
      const statusCode = e?.header.status_code ?? '';
      let message = ['Unexpected error occurred !!'];
      if (
        e.body
        && e.body.data.errors
      ) {
        message = Object.values(e.body.data.errors).flat() as string[];
      } else if (
        e.header
        && e.header.messages
        && e.header.messages.message
      ) {
        message = Object.values(e.header.messages);
      }
      dispatch(dialogAction.pushMessage({
        title: `エラー${statusCode ? ` (CODE : ${statusCode})` : ''}`,
        message,
        buttons: [
          {
            label: 'CLOSE',
            callback: () => {
              dispatch(dialogAction.pop());
            },
          }
        ],
      }));
    };
    // - env.json の取得 -
    const cache = String(new Date().getTime());
    fetch(`./server-env/mode.json?cache=${cache}`)
      .then((res) => {
        if (res.ok) {
          return res;
        }
        throw res;
      })
      .then((res) => res.json())
      .then((json) => {
        if (type) {
          dispatch(serverEnvAction.set({
            type: type,
            mode: json?.mode ?? '',
            ispUrl: json?.ispUrl ?? '',
            companyUrl: json?.companyUrl ?? '',
            manuelUrl: json?.manuelUrl ?? '',
            startGuideUrl: json?.startGuideUrl ?? '',
            adminGuideUrl: json?.adminGuideUrl ?? '',
            totalGuideUrl: json?.totalGuideUrl ?? '',
            rulesUrl: json?.rulesUrl ?? '',
          }))
        }
        switch (json.mode) {
          case 'local':
            if (type === 'isp') {
              document.title = 'B-LOOP管理-ローカル'
              break
            } else if (type === 'company') {
              document.title = '企業管理-ローカル'
              break
            } else {
              document.title = ''
              break
            }
          case 'develop':
            if (type === 'isp') {
              document.title = 'B-LOOP管理-開発'
              break
            } else if (type === 'company') {
              document.title = '企業管理-開発'
              break
            } else {
              document.title = ''
              break
            }
          case 'develop2':
            if (type === 'isp') {
              document.title = 'B-LOOP管理-ステージング'
              break
            } else if (type === 'company') {
              document.title = '企業管理-ステージング'
              break
            } else {
              document.title = ''
              break
            }
          case 'staging':
            if (type === 'isp') {
              document.title = 'B-LOOP管理-ステージング'
              break
            } else if (type === 'company') {
              document.title = '企業管理-ステージング'
              break
            } else {
              document.title = ''
              break
            }
          case 'production':
            document.title = 'B-LOOP総合管理'
            break
          default:
            document.title = ''
            break
        }
        console.log('json : ', json);
        fetch(`./env.json?cache=${cache}`)
          .then((res) => {
            if (res.ok) {
              return res;
            }
            throw res;
          })
          .then((res) => res.json())
          .then((json) => {
            dispatch(envAction.set({
              version: json.version,
              build: json.build,
            }));
            document.title += ` ${json.version}`;
          });
      })
      .catch((err) => {
        console.error('README にしたがって、mode.json を設置してください');
      });
  });

  return (
    <></>
  )
};
