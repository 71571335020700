import { Button } from "../../../ui/button/button"
import SyntaxHighlighter from "react-syntax-highlighter/dist/esm/default-highlight";
import { useCallback, useEffect, useMemo, useState } from "react";
import { dialogAction } from "../../../../slices/dialog-slice";
import './add-authorization.dialog.scss';
import { useAppDispatch } from "../../../../app/hooks";
import { DirectJsonEdit } from './direct-json-edit.tsx/direct-json-edit';
import { AuthorizationFormData, AuthorizeFormEdit } from "./authorize-form-edit/authorize-form-edit";
import { apiIsp, ApiManager } from '../../../../managers/api-manager';
import { useAuthManager } from '../hook/use-auth-manager';
import { IspLogOperation } from '../../../../models/logs/isp-log-operation';

type Props = {
  id?: number,
  callback: () => void,
  initSort?: number,
}


export const AddAuthorizationDialog = (props: Props) => {
  const { id, callback, initSort } = props;
  const isAdd = !id;
  const dispatch = useAppDispatch();
  const [json, setJson] = useState('');
  const [editableJson, setEditableJson] = useState('');
  const [requestParam, setRequestParam] = useState<AuthorizationFormData>({ kind: true, sort_order: initSort ?? null, File: null });
  const [isEdit, setIsEdit] = useState(isAdd);
  const [isJsonEdit, setIsJsonEdit] = useState(!isAdd);

  const { getDetail, init, info: data, disabledButton } = useAuthManager({ isAdd: !id, requestData: requestParam, isDirect: isJsonEdit, editableJsonData: editableJson });
  const setRequest = useCallback((setData: Partial<AuthorizationFormData>) => {
    const data = {...requestParam};
    setRequestParam({ ...requestParam, ...setData });
  }, [requestParam]);
  const jsonAlert = useMemo(() => {
    const alert = 'authoritiesの形式が正しくありません';
    const alert2 = 'テキストがjsonの形式になっていません';
    const alert3 = 'authoritiesは必ず定義してください。';
    try {
      const jsonObj = JSON.parse(editableJson);
      if (jsonObj?.authorities === undefined) return alert3;
      if (Array.isArray(jsonObj?.authorities)) {
        return jsonObj?.authorities.length ? alert : '';
      }
      if ((jsonObj !== null) && (typeof jsonObj === 'object')) {
        const values = Object.values(jsonObj.authorities);
        if (!values.length) return alert;
        return values.every((v) => typeof v === 'string') ? '' : alert;
      } else {
        return alert2;
      }
    } catch(e) {
      return alert2;
    }
  }, [editableJson]);
  
  const onChangeFile = useCallback((file: File) => {
    const reader = new FileReader();
    reader.onload = () => {
      const jsonText = reader.result as string;
      setEditableJson(jsonText);
    }
    reader.readAsText(file);
  }, []);

  const callbackDirectEditJson = useCallback((v: string) => {
      setEditableJson(v);
      setRequestParam(prev => ({...prev, file: null}));
  }, []);

  const addPost = useCallback(() => new Promise<void>((resolve, reject) => {
    if (!requestParam.File) return;
    apiIsp.master().authorities().post({
      kind: requestParam.kind ? 1 : 2,
      sort_order: requestParam.sort_order ?? 0,
      File: requestParam.File,
    }).then((res) => resolve())
    .catch(reject);
  }), [requestParam]);

  const editPost = useCallback(() => new Promise<void>((resolve, reject) => {
    if (!data) return;
    if (isJsonEdit) {
      apiIsp.master().authorities().detail(data.id).editDirect({
        definition: editableJson,
        kind: data.kind as 1 | 2,
        type: 1,
      })
      .then(() => resolve())
      .catch(reject);
    } else {
      const request = { 
        ...requestParam,
        kind: requestParam.kind ? 1 : 2,
        type: 2,
       } as any;
      if (!request.File) delete request.File;
      apiIsp.master().authorities().detail(data.id).editForm({
        ...request,
      }).then(() => resolve())
      .catch(reject);
    }
  }), [isJsonEdit, requestParam, data, editableJson]);

  const onSubmit = useCallback(async() => {
    if (disabledButton) return;
    try {
      if (data) {
        IspLogOperation('authManagerDetailEditPostClick');
        await editPost();
      } else {
        IspLogOperation('authManagerAddPostClick');
        await addPost();
      }
    } catch(e) {
      ApiManager.errorFunc(e);
      return;
    }
      dispatch(dialogAction.pushMessage({
        title: '完了',
      message: [
        `${data ? '更新' : '登録'}完了しました。`,
      ],
      buttons: [
        {
          label: 'OK',
          callback: () => {
            dispatch(dialogAction.popAll());
            callback();
          },
          color: 'primary',
        }
      ]
    }));

  }, [data, addPost, disabledButton, callback, dispatch]);

  const onEdit = useCallback(() => {
    IspLogOperation('authManagerDetailEditClick');
    setIsEdit(true)
  }, []);
  
  const onReturn = useCallback(() => {
    IspLogOperation('authManagerDetailEditReturnClick');
    setIsEdit(false);
  }, []);

  const onCloseDialog = useCallback(() => {
    IspLogOperation(!!id ? 'authManagerAddCancel' : 'authManagerDetailEditCancelClick');
    dispatch(dialogAction.pop())
  }, []);

  const onFormChange = useCallback((isDirect: boolean) => {
    IspLogOperation(isDirect ? 'authManagerDetailEditDirectClick' : 'authManagerDetailEditFormClick');
    setIsJsonEdit(isDirect);
  }, []);

  useEffect(() => {
    if (!id) return;
    getDetail(id, (v, res) => {
      setJson(v);
      setEditableJson(v);
      setRequestParam({ kind: res.kind === 1, File: null, sort_order: res.sort_order })
    });
  }, []);

  return (
    <>
      <div className={`add_authorization_dialog_inner${isAdd ? ' add' : ''}`}>
        <div className="header_cnt">
          <p>{isAdd ? '権限新規登録' : data?.system ?? ''}</p>
          { !isAdd &&  !isEdit && <Button label="編集" onClick={() => onEdit()} />}
          { !isAdd && isEdit && <Button label="戻る" onClick={() => onReturn()}/>}

        </div>
        { !isEdit ?
          (
            <div className="syntax_height_lighter_cnt">
              <SyntaxHighlighter
                language="json"
              >
                {json}
              </SyntaxHighlighter>
            </div>
          ) : (
            <>
              {!isAdd && (<div className="tab_btn_box">
                <Button
                  label="直接入力"
                  className={isJsonEdit ? 'selected' : ''}
                  onClick={() => onFormChange(true)}
                />
                <Button
                  className={!isJsonEdit ? 'selected' : ''}
                  label="フォーム入力"
                  onClick={() => onFormChange(false)}
                />
              </div>)}
              { isJsonEdit ? (
                <DirectJsonEdit
                  data={editableJson}
                  callback={callbackDirectEditJson}
                  alertMessage={jsonAlert}
                />
              ) : (
                <AuthorizeFormEdit
                  data={requestParam}
                  callback={(v) => setRequest(v)}
                  isAdd={isAdd}
                  onChangeFile={onChangeFile}
                />
              ) }
            </>
          )
        }
      </div>
      <footer className="add_authorization_dialog_footer">
        <Button color="tertiary" label="閉じる" onClick={() => onCloseDialog()} />
        {isEdit && <Button label={isAdd ? '登録' : '更新'} disabled={disabledButton} onClick={() => onSubmit()}/>}
      </footer>
    </>
  )
}