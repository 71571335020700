import { useCallback, useEffect, useMemo, useState } from "react";
import { Table } from "../../table/table"
import './member-select-dialog.scss';
import iconTop from "../../../assets/images/common/icon_up.svg";
import iconUnder from "../../../assets/images/common/icon_under.svg";
import { apiIsp, ApiManager } from '../../../managers/api-manager';
import { ApiGetCompanyMembersResponse } from "isp_cloud_api/dist/api/company-web/members/api-get-company-members.response";
import { Organization } from "../../../models/tree-view/models";
import { useTableSelect } from '../../table/hooks/use-table-select';
import { Button } from "../../ui/button/button";
import { dialogAction } from "../../../slices/dialog-slice";
import { useAppDispatch } from '../../../app/hooks';
import { GetResponseMasterDestinationsDataEntity } from "isp_cloud_api/dist/api/isp-web/master/requests/destinations";
import { useRequestOrganizationsList } from "./hooks/use-request-organizations-list";
import { IspLogOperation } from '../../../models/logs/isp-log-operation';

type ListData = GetResponseMasterDestinationsDataEntity['persons'];

type Props = {
  registerList: ListData,
  mode: 'persons' | 'organizations',
  onSuccess: () => void,
}

export const MemberSelectDialog = (props: Props) => {
  const { registerList, mode, onSuccess } = props;
  const dispatch = useAppDispatch();
  const [selectedMembers, setSelectedMembers] = useState<ListData>([]);
  const { organizations } = useRequestOrganizationsList((res) => {
    const filterData = res.filter((v) => registerList.find((data) => data.organization_code === v.organization_code));
    setSelectedMembers(filterData);
  });
  const { originList, setList } = organizations;
  const [sortBy, setSortBy] = useState(0);
  const [highlow, setHighLow] = useState(0);
  const { selected, onSelect, resetSelect } = useTableSelect<ListData[0]>({ list: originList, idKey: 'organization_code', ignoreList: selectedMembers });
  const buttonSelected = useTableSelect<any>({ list: selectedMembers, idKey: 'organization_code' });
  const list = useMemo(() => {
    return originList.filter((data) => !selectedMembers.map((v) => v.organization_code).includes(data.organization_code));
  }, [originList, selectedMembers]);
  const tableBody = useMemo(() => {
    return list.map((data) => ([
      data.organization_name,
    ]))
  }, [list]);

  const logLabelKey = useMemo(() => {
    return mode === 'persons' ? 'Person' : 'Organization';
  }, [mode]);

  const onClickJoinMember = useCallback(() => {
    const selectedRowData = originList.filter((data) => selected.map((v) => v.id).includes(data.organization_code));
    setSelectedMembers(prev => [...prev, ...selectedRowData]);
    resetSelect();
    buttonSelected.resetSelect();
  }, [selected, resetSelect, originList, buttonSelected]);

  const onClickExclusionMember = useCallback(() => {
    const filter = selectedMembers.filter((v) => !buttonSelected.selected.map((data) => data.id).includes(v.organization_code));
    setSelectedMembers([...filter]);
    resetSelect();
    buttonSelected.resetSelect();
  }, [buttonSelected, selectedMembers, resetSelect]);

  const onSubmit = useCallback(() => {
    const LogLabel = `requestManager${logLabelKey}SettingRegisterClick` as const;
    const kind = mode === 'persons' ? 1 : 2;
    IspLogOperation(LogLabel);
    apiIsp.master().requests().destinations().post({
      kind,
      organizations: selectedMembers.map((v) => v.organization_code),
    }).then(() => {
      onSuccess();
      dispatch(dialogAction.pushMessage({
        title: '完了',
        message: ['登録が完了しました。'],
        buttons: [{
          label: 'OK',
          callback: () => {
            dispatch(dialogAction.popAll());
          },
        }],
      }));
    }).catch(ApiManager.errorFunc)
  }, [selectedMembers, mode, onSuccess, dispatch, logLabelKey]);

  const onCancel = useCallback(() => {
    const LogLabel = `requestManager${logLabelKey}SettingCancelClick` as const;
    IspLogOperation(LogLabel);
    dispatch(dialogAction.pop())
  }, [logLabelKey, dispatch])

  return (
    <div className="member_select_dialog__body">
      <div className="main_cnt_wrap">
        <div className="table_box">
          <Table
            layoutStyle="table_sticky"
            head={['組織']}
            selectedList={selected.map((v) => v.index)}
            body={tableBody}
            onClickRow={onSelect}
            alignList={['c']}
            setHighlow={setHighLow}
            setSortBy={setSortBy}
          />
        </div>
        <div className="select_button_cnt">
          <button type="button" onClick={() => onClickJoinMember()}>
            <img src={iconUnder} alt="下" />
          </button>
          <button type="button" onClick={() => onClickExclusionMember()}>
            <img src={iconTop} alt="上" />
          </button>
        </div>
        <div className="member_cnt">
          { selectedMembers.map((data, i) => (
            <button
              key={`button_${data.organization_code}}`}
              onClick={(e) => buttonSelected.onSelect(i, e)}
              className={buttonSelected.selected.map((v) => v.index).includes(i) ? 'active' : ''}
            >
              {data.organization_name}
            </button>
          )) }
        </div>
      </div>
      <div className="member_select_dialog__actions">
        <Button
          label="キャンセル"
          color="tertiary"
          onClick={onCancel}
          />
        <Button
          label="登録"
          disabled={!selectedMembers.length}
          onClick={onSubmit}
          />
      </div>

    </div>
  )
}