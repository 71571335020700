import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Header } from '../../ui/header/header';
import { SideBar } from '../../ui/sidebar/sidebar';
import { Input } from '../../ui/input/input';
import { Button } from '../../ui/button/button';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { RoutingPath } from '../../../routes/routing-path';
import { PageComponentDefaultProps } from '../../../models/page-component-default-props';
import { breadcrumbParts } from '../../../models/breadcrumb-parts';
import { BreadcrumbList } from '../../ui/breadcrumb-list/breadcrumb-list';
import { Select } from '../../ui/select/select';
import { useAppSelector } from '../../../app/hooks';
import { apiIspCommonAction } from '../../../slices/api-isp/api-isp-common-slicer';
import * as lodash from 'lodash';
import { dialogAction } from '../../../slices/dialog-slice';
import { OrganizationSelectDialog } from '../../dialog/organization-select-dialog';
import { apiCompany, apiIsp } from '../../../managers/api-manager';
import { apiIspCompanyUsersAction } from '../../../slices/api-isp/api-isp-company-users-slice';
import { ApiGetOrganizationsResponse } from 'isp_cloud_api/dist/api/isp-web/companies/organizations/api-get-organizations.response';
import { ApiGetCompanyMemberDetailResponse } from 'isp_cloud_api/dist/api/isp-web/companies/members/api-get-company-member-detail.response';
import { useDidMount } from '../../../hooks/life-cycle';
import { QueryParamFormatter } from '../../../utilities/query-param-formatter';
import { CompanyData } from '../../../models/company-data';
import { apiCompanyCommonAction } from '../../../slices/api-company/api-company-common-slicer';
import { apiCompanyUsersAction } from '../../../slices/api-company/api-company-users-slice';
import { CheckAllValid } from '../../../managers/validation/validation';
import { ValidationFactory } from '../../../managers/validation/validation-factory';
import { ApiGetCompanyDetailResponse as ApiGetIspCompanyDetailResponse } from 'isp_cloud_api/dist/api/isp-web/companies/api-get-company-detail.response';
import { ApiGetCompanyDetailResponse } from 'isp_cloud_api/dist/api/company-web/companies/api-get-company-detail.response';
import { companyAction, CompanyParams } from '../../../slices/company-slice';
import { IspLogOperation } from '../../../models/logs/isp-log-operation';
import { CompanyLogOperation } from '../../../models/logs/company-log-operation';
import { AppAuthModel } from '../../../models/authority/app-auth-model';
import { cloneDeep } from 'lodash';
import { AuthContainer, UserCadectChildAuthBase, UserCadectParentAuthBase } from './auth-container/auth-container';
import { useGetAuth } from './hooks/use-get-auth';

const ispBreadcrumbList = [
  breadcrumbParts.isp.home,
  breadcrumbParts.isp.companyList,
  breadcrumbParts.isp.companyDetail,
  breadcrumbParts.isp.companyUserList,
  breadcrumbParts.isp.companyUserEdit,
];
const companyBreadcrumbList = [
  breadcrumbParts.company.home,
  breadcrumbParts.company.userList,
  breadcrumbParts.company.userEdit,
];

type Props = {
  companyCode?: string,
  memberId: string,
} & PageComponentDefaultProps;

// バリデーション用意
const validations = {
  require: ValidationFactory('requireEdit'),
  length30: ValidationFactory('length30'),
  length80: ValidationFactory('length80'),
  length256: ValidationFactory('length256'),
  kana: ValidationFactory('kana'),
};

export const CompanyEditUser = (props: Props) => {
  const { apiManger, companyCode, memberId } = props;
  const dispatch = useDispatch();
  const { industryList, occupationList } = useAppSelector((state) => ({
    ...state.commonData,
  }), lodash.isEqual);

  const [breadcrumbList, setBreadcrumbList] = useState(
    apiManger.type === 'isp' ?
      ispBreadcrumbList :
      companyBreadcrumbList,
  );
  // -------------------- state --------------------
  const [orgData, setOrgData] = useState<{ id: string, label: string; }>({ id: '', label: '' });
  const [authModel, setAuthModel] = useState<AppAuthModel>(new AppAuthModel());
  const [email, setEmail] = useState('');
  const [lastNameKanji, setLastNameKanji] = useState('');
  const [firstNameKanji, setFirstNameKanji] = useState('');
  const [lastNameKana, setLastNameKana] = useState('');
  const [firstNameKana, setFirstNameKana] = useState('');
  const [previewName, setPreviewName] = useState('');
  const [occupation, setOccupation] = useState('');
  const [authority, setAuthority] = useState(2);
  const [_authority, set_Authority] = useState(2);
  const [information, setInformation] = useState(false);
  const [logReference, setLogReference] = useState(false);
  const [cadectBasis, setCadectBasis] = useState(false);
  const [cadectEco, setCadectEco] = useState(false);
  const [cadectAir, setCadectAir] = useState(false);
  const [cadectDisaster, setCadectDisaster] = useState(false);
  const [cadect5, setCadect5] = useState(false);
  const [cadect1UseCount, setCadect1UseCount] = useState(0);
  const [cadect2UseCount, setCadect2UseCount] = useState(0);
  const [cadect3UseCount, setCadect3UseCount] = useState(0);
  const [cadect4UseCount, setCadect4UseCount] = useState(0);
  const [cadect5UseCount, setCadect5UseCount] = useState(0);
  const [infoUseCount, setInfoUseCount] = useState(0);
  const [logUseCount, setLogUseCount] = useState(0);
  const [resolveFlag, setResolveFlag] = useState(false);

  const [isSupport, setIsSupport] = useState(false);

  const { userInfo } = useAppSelector((state) => state.loginIspUserInfo);
  const { companyUserInfo } = useAppSelector((state) => state.loginCompanyUserInfo);
  const { breadcrumbName } = useAppSelector((state) => state.company);

  const [companyData, setCompanyData] = useState<CompanyParams>();
  
  const { getMasterUserIspAuth, getMasterUserRestoreIspAuth, getCompanyAuthForCompany, getMasterUserRestoreCompanyAuth } = useGetAuth();

  const disableAuth = useMemo(() => {
    return {
      log: (!logReference && (((logUseCount === companyData?.log_use_count) && !isSupport) || (authority !== 1))) ? 'disabled' : '',
      info: (!information && (((infoUseCount === companyData?.info_use_count) && !isSupport) || (authority !== 1))) ? 'disabled' : '',
    }
  }, [
    isSupport, companyData, authority,
    logReference, logUseCount,
    information, infoUseCount,
  ]);

  // 企業オーナー
  const [isOwner, setIsOwner] = useState(false);
  const [owner, setOwner] = useState(false);
  const handlerChangeIsOwner = useCallback((v) => {
    setIsOwner(!v);
    if (v) {
      handleChangeAuthority(_authority);
    } else {
      handleChangeAuthority(1);
    }
  }, [_authority]);

  // 有効 (未退職者)
  const [valid, setValid] = useState(true);
  const handlerChangeValid = useCallback((v) => {
    setValid(!v);
  }, []);
  // -------------------- イベント --------------------
  /** 組織指定押下 */
  const handleClickOrgDesignation = useCallback(
    () => {
      if (apiManger.type === 'isp') {
        IspLogOperation('companyUserEditOrganizationSelect', () => {
          apiIsp.companies(companyCode).organizations().get()
            .then((res) => {
              IspLogOperation('companyUserEditOrganizationList')
              const orgList = (res as ApiGetOrganizationsResponse).body.data;
              dispatch(dialogAction.push({
                title: '組織指定',
                element: <OrganizationSelectDialog
                  orgData={orgList as any}
                  selectOrg={orgData.id}
                  callback={(res) => {
                    IspLogOperation('companyUserEditOrganizationAddClick')
                    setOrgData(res);
                  }}
                  mode={'edit'}
                />,
              }));
            });
        });
      } else {
        CompanyLogOperation('companyUserEditOrganizationSelect', () => {
          apiCompany.organizations().get()
            .then((res) => {
              CompanyLogOperation( 'companyUserEditOrganizationList')
              const orgList = (res as ApiGetOrganizationsResponse).body.data;
              dispatch(dialogAction.push({
                title: '組織指定',
                element: <OrganizationSelectDialog
                  orgData={orgList as any}
                  selectOrg={orgData.id}
                  callback={(res) => {
                    CompanyLogOperation('companyUserEditOrganizationAddClick')
                    setOrgData(res);
                  }}
                  mode={'edit'}
                />,
              }));
            });
        });
      }
    },
    [orgData],
  );
  /** ユーザー名変更 */
  const handleChangeLastNameKanji = useCallback(
    (v) => {
      setLastNameKanji(v);
    },
    [],
  );
  /** ユーザー姓変更 */
  const handleChangeFirstNameKanji = useCallback(
    (v) => {
      setFirstNameKanji(v);
    },
    [],
  );
  /** ユーザー名カナ変更 */
  const handleChangeLastNameKana = useCallback(
    (v) => {
      setLastNameKana(v);
    },
    [],
  );
  /** ユーザー姓カナ変更 */
  const handleChangeFirstNameKana = useCallback(
    (v) => {
      setFirstNameKana(v);
    },
    [],
  );
  /** 表示名変更 */
  const handleChangePreviewName = useCallback(
    (v) => {
      setPreviewName(v);
    },
    [],
  );
  /** 職種セレクト */
  const handleChangeOccupation = useCallback(
    (v) => {
      setOccupation(v);
    },
    [],
  );
  /** 権限セレクト */
  const handleChangeAuthority = useCallback(
    (v) => {
      if (v !== 1) {
        if (information) {
          setInformation(false);
          setInfoUseCount(prev => --prev);
        }
        if (logReference) {
          setLogReference(false);
          setLogUseCount(prev => --prev);
        }
      }
      setAuthority(v);
    },
    [information, logReference, infoUseCount, logUseCount],
  );
  /** インフォメーション変更 */
  const handleChangeInformation = useCallback(
    (v) => {
      let count = infoUseCount;
      v ? count += 1
        : count -= 1;
      setInfoUseCount(count);
      setInformation(v);
    },
    [infoUseCount],
  );
  /** ログ変更 */
  const handleChangeLogReference = useCallback(
    (v) => {
      let count = logUseCount;
      v ? count += 1
        : count -= 1;
      setLogUseCount(count);
      setLogReference(v);
    },
    [logUseCount],
  );
  /** キャンセル押下 */
  const handleClickCancel = useCallback(
    () => {
      if (apiManger.type === 'isp') {
        IspLogOperation('companyUserAddCancel', () => {
          const path = RoutingPath.ispCompanyUserList
            .replace(':companyCode', companyCode!);
          const query = QueryParamFormatter.queryParse();
          const _query = QueryParamFormatter.queryCompose(query);
          dispatch(push(path + '?' + _query));
        });
      } else {
        CompanyLogOperation('companyUserEditCancel', () => {
          const path = RoutingPath.companyUserList;
          const query = QueryParamFormatter.queryParse();
          const _query = QueryParamFormatter.queryCompose(query);
          dispatch(push(path + '?' + _query));
        });
      }
    },
    [],
  );

  // バリデーションのトータルチェック
  // const isDisabled = CheckAllValid(validations)
  const [isDisabled, setIsDisabled] = useState(CheckAllValid(validations));
  const orgRef = useRef<HTMLInputElement>(null);
  const [orgFlag, setOrgFlag] = useState(false);

  // 組織指定のバリデーションチェック
  const checkValidOrgInput = useCallback(() => {
    if (!orgRef.current) {
      return;
    }
    orgRef.current.focus();
    orgRef.current.blur();
    setIsDisabled(CheckAllValid(validations));
  }, [isDisabled]);
  useEffect(() => {
    if (orgFlag === false) {
      return setOrgFlag(true);
    }
    checkValidOrgInput();
  }, [orgData]);

  // バリデーション自動チェック
  useEffect(() => {
    setIsDisabled(CheckAllValid(validations));
  }, [lastNameKanji,
    firstNameKanji,
    lastNameKana,
    firstNameKana,
    previewName,
    occupation]);

  /** ユーザー更新処理 */
  const updateCompanyUser = () => {
    if (apiManger.type === 'isp') {
      // ISP管理
      const uniqueCadect = authModel.getUniqueCadectData();
      const cadectData = {
        is_cadect1: !!uniqueCadect?.checked,
        is_cadect2: !!uniqueCadect?.['model-eco'],
        is_cadect3: !!uniqueCadect?.['model-air-conditioning'],
        is_cadect4: !!uniqueCadect?.['model-disaster-prevention'],
        is_cadect5: !!uniqueCadect?.['zebzeh-simple-simulation'],
      }
      dispatch(apiIspCompanyUsersAction.update({
        companyCode: companyCode!,
        userId: memberId,
        param: {
          organization_code: orgData.id,
          family_name: lastNameKanji,
          name: firstNameKanji,
          family_name_kana: lastNameKana,
          name_kana: firstNameKana,
          display_name: previewName,
          industry_id: 10,
          occupation_id: Number(occupation),
          role: authority,
          is_information: information,
          is_log_admin: logReference,
          ...cadectData,
          is_owner: isOwner,
          is_retirement: !valid,
          authorities: authModel.createJson(),
        },
        callback: {
          success: () => {
            dispatch(dialogAction.pushMessage({
              title: '完了',
              message: ['更新しました'],
              buttons: [{
                label: 'OK',
                callback: () => {
                  dispatch(dialogAction.pop());
                  const path = RoutingPath.ispCompanyUserList
                    .replace(':companyCode', companyCode!);
                  const query = QueryParamFormatter.queryParse();
                  const _query = QueryParamFormatter.queryCompose(query);
                  dispatch(push(path + '?' + _query));
                },
              }],
            }));
          },
          failed: () => setIsDisabled(false),
        },
      }));
    } else {
      // 企業管理
      dispatch(apiCompanyUsersAction.update({
        memberId: memberId,
        param: {
          organization_code: orgData.id,
          family_name: lastNameKanji,
          name: firstNameKanji,
          family_name_kana: lastNameKana,
          name_kana: firstNameKana,
          display_name: previewName,
          industry_id: 10,
          occupation_id: Number(occupation),
          role: authority,
          is_information: information,
          is_log_admin: logReference,
          is_cadect1: cadectBasis,
          is_cadect2: cadectEco,
          is_cadect3: cadectAir,
          is_cadect4: cadectDisaster,
          is_cadect5: cadect5,
          is_owner: isOwner,
          is_retirement: !valid,
          authorities: authModel.createJson(),
        },
        callback: {
          success: () => {
            dispatch(dialogAction.pushMessage({
              title: '完了',
              message: ['更新しました'],
              buttons: [{
                label: 'OK',
                callback: () => {
                  dispatch(dialogAction.pop());
                  const path = RoutingPath.companyUserList;
                  const query = QueryParamFormatter.queryParse();
                  const _query = QueryParamFormatter.queryCompose(query);
                  dispatch(push(path + '?' + _query));
                },
              }],
            }));
          },
          failed: () => setIsDisabled(false),
        },
      }));
    }
  };
  /** 更新押下 */
  const handleClickUpdate = () => {
    setIsDisabled(true);
    if (!valid) {
      // 退職アラート
      dispatch(dialogAction.pushMessage({
        title: '確認',
        message: ['退職したユーザーは全ての権限を失います', 'このユーザーを退職させてもよろしいですか'],
        buttons: [{
          label: 'キャンセル',
          callback: () => {
            dispatch(dialogAction.pop());
            setIsDisabled(false);
          },
        },
          {
            label: 'OK',
            callback: () => {
              dispatch(dialogAction.pop());
              updateCompanyUser();
            },
          }],
      }));
      return;
    }
    updateCompanyUser();
  };
  const occupations = useMemo(() => {
    return [...occupationList].map((v) => ({
      key: String(v.id),
      label: v.name,
    }));
  }, [occupationList]);

  const createChildCadectData = useCallback((childModel: AppAuthModel['list'][0]['authorities'][0]) => {
    const calcCheckCount = (() => {
      if (childModel.defaultValue) {
        return childModel.checked ? 0 : -1;
      } else {
        return childModel.checked ? 1 : 0;
      }
    })();
    switch (childModel.authority) {
      case 'model-eco':
        const ecoLabel = isSupport ? '' : ` （${cadect2UseCount + calcCheckCount}名設定済み / ${companyData?.cadect2_use_count}名）`;
        return { 
          label: `CADECT-（省エネ）利用${ecoLabel}`,
          disabled: !childModel.defaultValue && (((cadect2UseCount) === companyData?.cadect2_use_count) && !isSupport)
        };
      case 'model-air-conditioning':
        const ariLabel = isSupport ? '' : ` （${cadect3UseCount + calcCheckCount}名設定済み / ${companyData?.cadect3_use_count}名）`;
        return {
          label: `CADECT-（空調）利用${ariLabel}`,
          disabled: !childModel.defaultValue && (((cadect3UseCount) === companyData?.cadect3_use_count) && !isSupport)
        };
      case 'model-disaster-prevention':
        const disasterLabel = isSupport ? '' : ` （${cadect4UseCount + calcCheckCount}名設定済み / ${companyData?.cadect4_use_count}名）`;
        return { 
          label: `CADECT-（防災）利用${disasterLabel}`,
          disabled: !childModel.defaultValue && (((cadect4UseCount) === companyData?.cadect4_use_count) && !isSupport)
        };
      case 'zebzeh-simple-simulation':
        const zebzeh = isSupport ? '' : ` （${cadect5UseCount + calcCheckCount}名設定済み / ${companyData?.cadect5_use_count}名）`;
        return { 
          label: `ZEB/ZEH-利用${zebzeh}`,
          disabled: !childModel.defaultValue && (((cadect5UseCount) === companyData?.cadect5_use_count) && !isSupport)
        };
      default:
        return { label: '', disabled: true };
    }    
  }, [cadect2UseCount, companyData, cadectEco, cadect3UseCount, cadectAir, cadectDisaster, cadect4UseCount, isSupport, cadect5, cadect5UseCount, isSupport]);

  const createModelData = useCallback(async(isIsp?: boolean) => {
    const model = cloneDeep(authModel)
    if (isIsp) {
      const companyData = await getMasterUserIspAuth(companyCode ?? '');
      const userData = await getMasterUserRestoreIspAuth((companyCode ?? ''), memberId);
      model.createList(companyData, userData);
    } else {
      const companyData = await getCompanyAuthForCompany();
      const userData = await getMasterUserRestoreCompanyAuth(memberId);
      model.createList(companyData, userData);
    }
    setAuthModel(model)

  }, [authModel, companyCode, memberId]);

  // -------------------- effect --------------------
  /** 企業情報取得（操作権限の分母取得のため） */
  useEffect(() => {
    let unmounted = false;
    if (apiManger.type === 'isp') {
      // ISP管理
      if (!userInfo.user_id) {
        return;
      }
      createModelData(true);

      if (breadcrumbName) {
        const userListPath = RoutingPath.ispCompanyUserList
          .replace(':companyCode', companyCode!);
        breadcrumbParts.isp.companyUserList.route = userListPath;
        const detailPath = RoutingPath.ispCompanyDetail
          .replace(':companyCode', companyCode!);
        breadcrumbParts.isp.companyDetail.route = detailPath;
        breadcrumbParts.isp.companyDetail.label = breadcrumbName.length > 20 ?
          breadcrumbName.slice(0, 20) + '...' :
          breadcrumbName;
        setBreadcrumbList([
          breadcrumbParts.isp.home,
          breadcrumbParts.isp.companyList,
          breadcrumbParts.isp.companyDetail,
          breadcrumbParts.isp.companyUserList,
          breadcrumbParts.isp.companyUserEdit,
        ]);
        setResolveFlag(true);
      }
      (apiIsp.companies(companyCode).get() as Promise<ApiGetIspCompanyDetailResponse>)
        .then((v) => {
          if (!unmounted) {
            const data: CompanyParams = v.body.data;
            setCompanyData(v.body.data);
            setCadect1UseCount(data?.user_cadect1_use_count!  - (data as any)?.support_cadect1_use_count!);
            setCadect2UseCount(data?.user_cadect2_use_count!  - (data as any)?.support_cadect2_use_count!);
            setCadect3UseCount(data?.user_cadect3_use_count!  - (data as any)?.support_cadect3_use_count!);
            setCadect4UseCount(data?.user_cadect4_use_count!  - (data as any)?.support_cadect4_use_count!);
            setCadect5UseCount(data?.user_cadect5_use_count!  - (data as any)?.support_cadect5_use_count!);
            setInfoUseCount(data?.user_info_use_count! - (data as any)?.support_info_use_count!);
            setLogUseCount(data?.user_log_use_count! - (data as any)?.support_log_use_count!);
            if (!breadcrumbName) {
              const userListPath = RoutingPath.ispCompanyUserList
                .replace(':companyCode', companyCode!);
              breadcrumbParts.isp.companyUserList.route = userListPath;
              const detailPath = RoutingPath.ispCompanyDetail
                .replace(':companyCode', companyCode!);
              dispatch(companyAction.setBreadcrumbName({
                breadcrumbName: data.company_name,
              }));
              breadcrumbParts.isp.companyDetail.route = detailPath;
              breadcrumbParts.isp.companyDetail.label = data.company_name.length > 20 ?
                data.company_name.slice(0, 20) + '...' :
                data.company_name;
              setBreadcrumbList([
                breadcrumbParts.isp.home,
                breadcrumbParts.isp.companyList,
                breadcrumbParts.isp.companyDetail,
                breadcrumbParts.isp.companyUserList,
                breadcrumbParts.isp.companyUserEdit,
              ]);
              setResolveFlag(true);
            }
          }
        });
    } else {
      // 企業管理
      if (!companyUserInfo.member_id) {
        return;
      }
      createModelData();

      (apiCompany.companies().get() as Promise<ApiGetCompanyDetailResponse>)
        .then((v) => {
          if (!unmounted) {
            const data = v.body.data;
            setCompanyData(v.body.data as any);
            setCadect1UseCount(data?.cadect1_count!);
            setCadect2UseCount(data?.cadect2_count!);
            setCadect3UseCount(data?.cadect3_count!);
            setCadect4UseCount(data?.cadect4_count!);
            setCadect5UseCount(data?.cadect5_count!);
            setInfoUseCount(data?.info_count!);
            setLogUseCount(data?.log_count!);
          }
        });
      setBreadcrumbList(companyBreadcrumbList);
      setResolveFlag(true);
    }
    return () => {
      unmounted = true;
    };
  }, []);

  useDidMount(() => {
    if (apiManger.type === 'isp') {
      if (!userInfo.user_id) {
        return;
      }
      // dispatch(apiIspCommonAction.getIndustryList());
      dispatch(apiIspCommonAction.getOccupationList());
    } else {
      if (!companyUserInfo.member_id) {
        return;
      }
      // dispatch(apiCompanyCommonAction.getIndustryList());
      dispatch(apiCompanyCommonAction.getOccupationList());
    }
  });
  /** 企業ユーザー詳細取得 */
  useEffect(() => {
    let unmounted = false;
    if (apiManger.type === 'isp') {
      // ISP管理
      if (!userInfo.user_id) {
        return;
      }
      (apiIsp.companies(companyCode).members(memberId).get() as Promise<ApiGetCompanyMemberDetailResponse>)
        .then((v: any) => {
          const {
            organization_code,
            organization_name,
            email,
            occupation_id,
            industry_id,
            family_name,
            name,
            family_name_kana,
            name_kana,
            display_name,
            role,
            is_owner,
            is_valid,
            created_at,
            status,
            is_information,
            is_log_admin,
            is_cadect1,
            is_cadect2,
            is_cadect3,
            is_cadect4,
            is_cadect5,
          } = v.body.data;
          if (!unmounted) {
            setOrgData({ id: organization_code, label: organization_name });
            setEmail(email);
            setLastNameKanji(family_name);
            setFirstNameKanji(name);
            setLastNameKana(family_name_kana);
            setFirstNameKana(name_kana);
            setPreviewName(display_name);
            setOccupation(String(occupation_id));
            setAuthority(role);
            set_Authority(role);
            setInformation(is_information);
            setLogReference(is_log_admin);
            setCadectBasis(is_cadect1);
            setCadectEco(is_cadect2);
            setCadectAir(is_cadect3);
            setCadectDisaster(is_cadect4);
            setCadect5(is_cadect5);
            setIsOwner(is_owner);
            setOwner(is_owner);
            setValid(is_valid);
            setIsSupport(!!v.body.data?.is_support);
          }
        });
    } else {
      // 企業管理
      if (!companyUserInfo.member_id) {
        return;
      }
      (apiCompany.members(memberId).get() as Promise<ApiGetCompanyMemberDetailResponse>)
        .then((v: any) => {
          const {
            organization_code,
            organization_name,
            email,
            occupation_id,
            industry_id,
            family_name,
            name,
            family_name_kana,
            name_kana,
            display_name,
            role,
            is_owner,
            is_valid,
            created_at,
            status,
            is_information,
            is_log_admin,
            is_cadect1,
            is_cadect2,
            is_cadect3,
            is_cadect4,
            is_cadect5,
          } = v.body.data;
          if (!unmounted) {
            setOrgData({ id: organization_code, label: organization_name });
            setEmail(email);
            setLastNameKanji(family_name);
            setFirstNameKanji(name);
            setLastNameKana(family_name_kana);
            setFirstNameKana(name_kana);
            setPreviewName(display_name);
            setOccupation(String(occupation_id));
            setAuthority(role);
            set_Authority(role);
            setInformation(is_information);
            setLogReference(is_log_admin);
            setCadectBasis(is_cadect1);
            setCadectEco(is_cadect2);
            setCadectAir(is_cadect3);
            setCadectDisaster(is_cadect4);
            setCadect5(is_cadect5);
            setIsOwner(is_owner);
            setOwner(is_owner);
            setValid(is_valid);
          }
        });
    }
    return () => {
      unmounted = true;
    };
  }, [companyCode, memberId]);
  
  return (
    <div
      id="App"
      className="company edit"
    >
      <SideBar
        currentPage="company-list"
        apiManger={apiManger.type}
      />
      <div className="main_cnt">
        <Header apiManger={apiManger.type} />
        <div className="inner">
          {breadcrumbName || resolveFlag ?
            <BreadcrumbList breadcrumbList={breadcrumbList} /> :
            <></>}
          <section>
            <header>
              <h2>ユーザー情報編集</h2>
            </header>
            <div className="edit_wrap">
              <div className="edit_box">
                <div className="item_wrap">
                  <div className="item_head">
                    組織名
                    <span className="required">必須</span>
                  </div>
                  <div className="item_cnt flex">
                    <div className="add_reset_wrap position_relative">
                      <Input
                        className="add_reset"
                        style={{
                          marginRight: '10px',
                          cursor: 'pointer',
                        }}
                        boxSize="medium"
                        readOnly
                        onClick={handleClickOrgDesignation}
                        value={orgData.label}
                        validations={[
                          validations.require,
                        ]}
                        ref={orgRef}
                      />
                      <Button
                        size="small"
                        custom="reset"
                        label=""
                        onClick={() => setOrgData({ id: '', label: '' })}
                      />
                    </div>
                    <Button
                      label="組織指定"
                      onClick={handleClickOrgDesignation}
                    />
                  </div>
                </div>
                <hr />
                <div className="item_wrap">
                  <div className="item_head">
                    メールアドレス
                    { !!isSupport && (
                      <span className="required" style={{background: '#17A3B4', width: 'fit-content', paddingLeft: '0.25rem', paddingRight: '0.25rem'}}>
                        サポート
                      </span>)
                    }
                  </div>
                  <div className="item_cnt">
                    <label className="text_box">{email}</label>
                  </div>
                </div>
                <hr />
                <div className="item_wrap">
                  <div className="item_head">
                    ユーザー名
                    <span className="required">必須</span>
                  </div>
                  <div className="item_cnt flex">
                    <div style={{ marginRight: '30px' }}>
                      <label className="fix_size">姓</label>
                      <Input
                        value={lastNameKanji}
                        onChange={(e) => handleChangeLastNameKanji(e.target.value)}
                        validations={[
                          validations.require,
                          validations.length30,
                        ]}
                      />
                    </div>
                    <div>
                      <label className="fix_size">名</label>
                      <Input
                        value={firstNameKanji}
                        onChange={(e) => handleChangeFirstNameKanji(e.target.value)}
                        validations={[
                          validations.require,
                          validations.length30,
                        ]}
                      />
                    </div>
                  </div>
                </div>
                <div className="item_wrap">
                  <div className="item_head">
                    ユーザー名(カナ)
                    <span className="required">必須</span>
                  </div>
                  <div className="item_cnt flex">
                    <div style={{ marginRight: '30px' }}>
                      <label className="fix_size">セイ</label>
                      <Input
                        value={lastNameKana}
                        onChange={(e) => handleChangeLastNameKana(e.target.value)}
                        validations={[
                          validations.require,
                          validations.length30,
                          validations.kana,
                        ]}
                      />
                    </div>
                    <div>
                      <label className="fix_size">メイ</label>
                      <Input
                        value={firstNameKana}
                        onChange={(e) => handleChangeFirstNameKana(e.target.value)}
                        validations={[
                          validations.require,
                          validations.length30,
                          validations.kana,
                        ]}
                      />
                    </div>
                  </div>
                </div>
                <hr />
                <div className="item_wrap">
                  <div className="item_head">
                    表示名
                    <span className="required">必須</span>
                  </div>
                  <div className="item_cnt">
                    <Input
                      value={previewName}
                      boxSize="medium"
                      onChange={(e) => handleChangePreviewName(e.target.value)}
                      validations={[
                        validations.require,
                        validations.length80,
                      ]}
                    />
                  </div>
                </div>
                <hr />
                <div className="item_wrap">
                  <div className="item_head">
                    職種
                    <span className="required">必須</span>
                  </div>
                  <div className="item_cnt">
                    <Select
                      value={occupation}
                      list={occupations}
                      onChange={handleChangeOccupation}
                      validations={[
                        validations.require,
                      ]}
                    />
                  </div>
                </div>
                <hr />
                <div className="item_wrap">
                  <div className="item_head">
                    ユーザー権限
                    <span className="required">必須</span>
                  </div>
                  <div className={`item_cnt ${isOwner && 'disabled'}`}>
                    <div className="radio_style">
                      <Input
                        id="authority_manager"
                        type="radio"
                        name="authority"
                        onChange={() => handleChangeAuthority(1)}
                        checked={authority === 1}
                      />
                      <label htmlFor="authority_manager">管理ユーザー</label>
                    </div>
                    <div className="radio_style">
                      <Input
                        id="authority_user"
                        type="radio"
                        name="authority"
                        onChange={() => handleChangeAuthority(2)}
                        checked={authority === 2}
                      />
                      <label htmlFor="authority_user">一般</label>
                    </div>
                    <div className="radio_style">
                      <Input
                        id="authority_guest"
                        type="radio"
                        name="authority"
                        onChange={() => handleChangeAuthority(3)}
                        checked={authority === 3}
                      />
                      <label htmlFor="authority_guest">ゲスト</label>
                    </div>
                  </div>
                </div>
                <hr />
                <div className="item_wrap">
                  <div className="item_head">
                    操作権限
                  </div>
                  <div className="item_cnt">
                    <div className="indent">
                      <div>
                        <div
                          className={`checkbox ${disableAuth.info}`}
                        >
                          <Input
                            id="information"
                            type="checkBox"
                            onChange={() => handleChangeInformation(!information)}
                            checked={information}
                          />
                          <label htmlFor="information">
                            インフォメーション利用
                            {isSupport ? '' :`（${infoUseCount}名設定済み / ${companyData?.info_use_count}名）`}
                          </label>
                        </div>
                      </div>
                      <div>
                        <div
                          className={`checkbox ${disableAuth.log}`}
                        >
                          <Input
                            id="reference"
                            type="checkBox"
                            onChange={() => handleChangeLogReference(!logReference)}
                            checked={logReference}
                          />
                          <label htmlFor="reference">
                            ログ参照利用
                            {isSupport ? '' :`（${logUseCount}名設定済み / ${companyData?.log_use_count}名）`}
                          </label>
                        </div>
                      </div>
                    </div>
                    <AuthContainer
                      list={authModel.list}
                      callback={setAuthModel}
                      isUser
                      CadectCheckBox={(_props) => (
                        <UserCadectChildAuthBase
                          {..._props}
                          cadectBasis={cadectBasis}
                          check={{
                            callback: setAuthModel,
                          }}
                          {...createChildCadectData(_props.childModel)}
                          disabled={createChildCadectData(_props.childModel).disabled || !_props.parentModel.checked}
                        />
                      )}
                      CadectMainCheckBox={(_props) => (
                        <UserCadectParentAuthBase
                          {..._props}
                          check={{
                            callback: setAuthModel,
                          }}
                          label={`CADECT-（基本）利用${isSupport ? '' : ` （${(cadect1UseCount + (() => {
                            if (_props.parentModel.defaultValue) {
                              return _props.parentModel.checked ? 0 : -1;
                            } else {
                              return _props.parentModel.checked ? 1 : 0;
                            }
                          })())}名設定済み / ${companyData?.cadect1_use_count}名）`}`}
                          disabled={(!_props.parentModel.defaultValue && (cadect1UseCount === companyData?.cadect1_use_count)) && !isSupport}
                        />
                      )}
                    />

                  </div>
                </div>
                <hr />
                <div className="item_wrap">
                  <div className="item_head">
                    権限
                  </div>
                  <div className="item_cnt">
                    <div
                      className={apiManger.type === 'company' ?
                        `checkbox ${(!(companyUserInfo.is_owner) || owner || !valid) && 'disabled'}`
                        : `checkbox ${(owner || !valid) && 'disabled'}`}
                    >
                      <Input
                        checked={isOwner}
                        type="checkbox"
                        id="setting_owner"
                        className="checkbox_style"
                        onChange={() => handlerChangeIsOwner(isOwner)}
                      />
                      <label htmlFor="setting_owner">オーナー設定</label>
                    </div>
                    <div
                      className={apiManger.type === 'company' ?
                        `checkbox ${(companyUserInfo.member_id === memberId || isOwner) && ' disabled'}`
                        : `checkbox ${isOwner && ' disabled'}`
                      }
                    >
                      <Input
                        checked={!valid}
                        type="checkbox"
                        id="retirement"
                        className="checkbox_style"
                        onChange={() => handlerChangeValid(valid)}
                      />
                      <label htmlFor="retirement">退職</label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="btn_box align_center">
                <Button
                  size="large"
                  color="tertiary"
                  label="キャンセル"
                  onClick={handleClickCancel}
                />
                <Button
                  size="large"
                  label="更新"
                  onClick={handleClickUpdate}
                  disabled={isDisabled}
                />
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>

  );
};
