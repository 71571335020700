import { GetResponseMasterDestinationsDataEntity } from "isp_cloud_api/dist/api/isp-web/master/requests/destinations";
import { useCallback, useEffect, useMemo, useState } from "react"
import { apiIsp, ApiManager } from '../../../../managers/api-manager';

export const useGetRequestsData = () => {
  const [info, setInfo] = useState<GetResponseMasterDestinationsDataEntity | null>(null);
  const persons = useMemo(() => info?.persons ?? [], [info]);
  const organizations = useMemo(() => info?.organizations ?? [], [info]);
  const getList = useCallback(() => {
    apiIsp.master().requests().destinations().get().then((res) => {
      setInfo(res.body.data);
    }).catch(ApiManager.errorFunc)
  }, []);

  useEffect(() => {
    getList();
  }, []);
  return { 
    getList,
    persons,
    organizations,
  }
}